import axios from 'axios'
import Vue from 'vue'
import { setupCache } from 'axios-cache-adapter'

const vue = new Vue()

const CACHED_ENDPOINTS = [/reports/]
const CACHE_MAX_AGE = 2 * 60 * 1000 // 2 minutes

const shouldCache = req => {
  const { url } = req
  const mappedEndpoints = CACHED_ENDPOINTS.map(pattern => {
    const regexp = new RegExp(pattern)
    return regexp.test(url)
  })
  return !mappedEndpoints.includes(true)
}

const cache = setupCache({
  exclude: {
    query: false,
    filter: req => shouldCache(req)
  },
  maxAge: CACHE_MAX_AGE,
  key: req => `${req.url}_${JSON.stringify(req.params)}`,
  invalidate: async (cfg, req) => {
    // check for invalidateCache in request payload
    let data
    try {
      data = JSON.parse(req.data)
    } catch (err) {
      data = {}
    }
    if (data.invalidateCache) {
      // delete cache entry
      await cfg.store.removeItem(cfg.uuid)

      // remove invalidateCache from request payload
      delete data.invalidateCache
      req.data = JSON.stringify(data)
    }
  },
  clearOnStale: true,
  clearOnError: true,
  debug: false
})

const config = {
  baseURL: process.env.VUE_APP_ROOT_API || 'http://localhost',
  adapter: cache.adapter
}

export const createInstance = () => {
  const instance = axios.create(config)

  instance.interceptors.request.use(config => {
    if (!config.headers.post['Content-Type']) {
      config.headers.post['Content-Type'] = 'application/json'
    }
    return config
  });

  instance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response && error.response.status === 401) {
        //logout user
        vue.$bus.$emit('forceLogout')
      }
      if (error.response) {
        return { data: error.response.data, status: error.response.status }
      } else {
        return Promise.reject(error)
      }
    }
  )

  instance.interceptors.request.use(
    request => {
      if (!request.url.includes('login')) {
        try {
          const { token } = JSON.parse(
            localStorage.getItem('user-token') || '{}'
          )
          request.headers.Authorization = `Bearer ${token}`
        } catch (err) {
          console.log(err)
        }
      }
      return request
    },
    err => Promise.reject(err)
  )

  // Add a response interceptor
  instance.interceptors.response.use(
    res => res,
    err => {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error === 'Licence needed'
      ) {
        vue.$bus.$emit('openDialog', 'licence')
      }
      return Promise.reject(err)
    }
  )
  return instance
}

export default createInstance()
