import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import {IState, ISystemSetting} from './types'

let envUrl = process.env.VUE_APP_ROOT_API || 'http://localhost'

if (envUrl === '/') {
  envUrl = window.location.origin.toString()
}

const url = new URL(envUrl)

const apiUrl = url.toString()

url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:'

const getDefaultState = (): IState => {
  return {
    dataLoaded: false,
    upgrades: [],
    status: null,
    error: null,
    version: "",
    environment: null,
    supportEmail: `${process.env.VUE_APP_SUPPORT_EMAIL ||
      'support@droneshield.com'}`,
    apiUrl,
    systemSetting: {} as ISystemSetting,
  }
}

const state = getDefaultState()

const resetState = (state: any) => {
  Object.assign(state, {
    ...getDefaultState(),
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
