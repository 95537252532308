import { ICannon, ICannonsState, ICannonsSet } from "./types"
import {ISentry} from "@/store/modules/sentries/types";
export default {
  status: (state: ICannonsState) => state.status,
  error: (state: ICannonsState) => state.error,
  activeCannonId: (state: ICannonsState) => state.activeCannonId,
  cannonsSet: (state: ICannonsState): ICannonsSet => state.cannonsSet,
  cannonsList: (state: ICannonsState): ICannon[] => Object.values(state.cannonsSet),
  cannonsLength: (state: ICannonsState): number => {
    return state.cannonsSet ? Object.values(state.cannonsSet).length : 0
  },
  activeCannon: (state: ICannonsState) => state.cannonsSet[state.activeCannonId],
  cannonById: (state: ICannonsState) => (id:number) => (state.cannonsSet || {})[id],

  allCannons: (state: ICannonsState): ICannon[] => Object.values(state.cannonsSet),
  allCannonsInSentry: (state: ICannonsState) => (
      sentryId: number
  ): ICannon[] => {
    return Object.values(state.cannonsSet).filter(
        (cannon: ICannon) => cannon.sentry_id === sentryId
    )
  },
  cannonsInSite: (state: ICannonsState) => (
      site: number,
      sentries: ISentry[]
  ): Record<string, ICannon> => {
    const siteSentryIds = sentries.map((sentry: ISentry) => sentry.id)
    const cannons = {}
    Object.values(state.cannonsSet).forEach((cannon: ICannon) => {
      if (siteSentryIds.includes(cannon.sentry_id)) {
        cannons[`${cannon.id}-${cannon.sentry_id}`] = cannon
      }
    })
    return cannons
  },

  highlightedCannon: (state: ICannonsState) => state.highlightedCannon,
  highlightedAllCannons: (state: ICannonsState) => state.highlightedAllCannons,
}
