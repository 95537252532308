import { Commit, Dispatch, Getter } from 'vuex'
import omit from 'lodash/omit'
import { apiRequest, axiosInstance } from '../../utils'

export default {
  FETCH_RECORDING: async ({ commit }, id) => {
    await apiRequest(
      'get',
      `api/recordings/${id}`,
      {},
      (data, headers) => {
        commit(`UPDATE_RECORDING`, data)
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit(`LOAD_RECORDINGS`, [])
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  DELETE_RECORDING: async ({ commit, dispatch }, recording) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'delete',
      `/api/recordings/${recording.id}`,
      { recording },
      (data, _) => {
        commit('REMOVE_RECORDING', recording.id)
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  DOWNLOAD_RECORDING: async ({ commit, dispatch }, {recordingId, filename}) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'get',
      `/api/recordings/${recordingId}/download/${filename}`,
      {  },
      (data, _) => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_AND_LOAD_RECORDING: async ({ state, dispatch }, recordingId) => {
    if (state.recordingsSet[recordingId]) {
      const exp = state.recordingsSet[recordingId].url_expiration
      const expiredUrl = !exp || Date.now() > Date.parse(exp)
      if (!expiredUrl) return state.recordingsSet[recordingId]
    }
    state.recordingsSet[recordingId] = {}
    await dispatch('FETCH_RECORDING', recordingId)
    return state.recordingsSet[recordingId]
  },
}