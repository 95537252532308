import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export interface IRealDroneState {
  id: string
  drone_id: string
  latitude: number | undefined
  longitude: number | undefined
}

export interface IRealDroneDetectionsState {
  detections: Array<IRealDroneState>
}

const getDefaultState = () => {
  return {
    detections: []
  }
}

const state = getDefaultState()

const resetState = (state: IRealDroneState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
