import { IMapLayer, IMapState, Map } from './types'

export default {
  exporting: (state: IMapState) => state.exporting,
  activeSiteMapLayer: (state: IMapState) => {
    return state.allMapLayers
  },
  siteMapLayers: (state: IMapState) => {
    return state.allMapLayers
  },
  fallbackMapLayer: (state, getters): IMapLayer => {
    return state.allMapLayers.basic
  },
  mapZoom: (state: IMapState) => {
    return state.mapZoom;
  },
  getMapLayerKey: (state: IMapState): string => {
    return state.mapTilerOnlineKey
  },
  activeMapLayer: (state: IMapState): Map => {
    return state.activeMapLayer || state.allMapLayers.basic
  },
  activeMapLayerName: (state: IMapState): string => {
    return state.activeMapLayerName || state.allMapLayers.basic.name
  },
  activeMapLayerKey: (state: IMapState): string => {
    return state.activeMapLayerKey || 'basic'
  }
}
