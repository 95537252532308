import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { getPersistent } from '../../utils'

const getDefaultState = () => {
  return {
    zoneTypes: ['alert', 'label'],
    zoneTypesDefeat: ['alert', 'disrupt', 'label'],
    zonesList: [],
    activeZoneId: null,
    activeZone: { id: null, name: 'None' },
    visibleZoneType: getPersistent('visibleZoneType', []),
    alertZoneFillPattern: getPersistent('alertZoneFillPattern', false),
    zoneTypeColors: {
      disrupt: {
        fillColor: '#2c0000',
        fillPattern: null,
        fillOpacity: 0.2,
        strokeColor: 'red',
        strokeOpacity: 0.8,
        strokeWeight: 2
      },
      alert: {
        fillColor: '#f57b15',
        fillPattern: null,
        fillOpacity: 0.2,
        strokeColor: '#f57b15',
        strokeOpacity: 0.8,
        strokeWeight: 2
      },
      label: {
        fillColor: '#032500',
        fillPattern: null,
        fillOpacity: 0.2,
        strokeColor: '#1db20f',
        strokeOpacity: 0.8,
        strokeWeight: 2
      }
    }
  }
}

const state = getDefaultState()

const resetState = state => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
