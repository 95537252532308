import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { IRecordingsState } from './types';

const getDefaultState = (): IRecordingsState => {
  return {
    recordingsSet: {},
    activeRecordingId: null,
    status: '',
    error: '',
  }
}

const state = getDefaultState()

const resetState = (state: IRecordingsState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}