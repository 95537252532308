











































































import Vue from 'vue'

import {mapState, mapGetters, mapActions} from 'vuex'
import TopNavActions from '@/components/Nav/TopNav/TopNavActions.vue'
import { ISite } from '@/store/modules/sites/types'
import CenterMapButton from '@/components/Nav/TopNav/CenterMapButton.vue'
import { TEnabledSiteControlFunc } from './types'

const DEFAULT_TIMEZONE = 'UTC'

const TOOLBAR_EXTENSION_THRESHOLD = 1120

export default Vue.extend({
  name: 'TopNav',
  components: {
    CenterMapButton,
    TopNavActions
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    status: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState('system', ['upgrades']),
    ...mapGetters('system', ['assetsPath']),
    ...mapGetters('sensors', { meta: 'meta' }),
    ...mapState('sites', {
      sitesSet: 'sitesSet',
      activeSiteId: 'activeSiteId'
    }),
    ...mapGetters('sites', ['activeSite']),
    ...mapGetters('users', ['isAuthorized']),
    showExtension(): boolean {
      return this.windowWidth <= TOOLBAR_EXTENSION_THRESHOLD
    },
    windowWidth(): number {
      return this.$vuetify.breakpoint.width
    },
    enabledSiteControl(): TEnabledSiteControlFunc {
      return (type: string) => {
        const enabledPages = ['Home', 'installations', 'Plans', 'Control Panel']
        if (type === 'picker') enabledPages.push('Zones', 'Report')
        return enabledPages.includes(this.$route.name)
      }
    },
    enabledMapZoomCenterButton(): boolean {
      // (Dashboard, Site Settings, Maps & Zones) are pages where 'Center map to site location' button should be active
      return ['Home', 'installations', 'Zones'].includes(this.$route.name)
    },
    showCenterMapButton(): boolean {
      return this.sites.length > 0
    },
    sites(): ISite[] {
      return Object.values(this.sitesSet) || []
    },
    topBrandImg(): string {
      return require(`@/assets/${this.assetsPath}top_brand.svg`)
    },
    topPoweredByImg(): string {
      return null
    },
    timezone(): string {
      return (this.activeSite && this.activeSite.timezone) || DEFAULT_TIMEZONE
    },
    version(): string {
      return this.$store?.state?.version || ''
    }
  },
  methods: {
    ...mapActions('notifications', { clearNotifications: 'clearDetections' }),
    ...mapActions('system', [
      'setDataLoadedState'
    ]),
    onChangeSite(event) {
      this.setDataLoadedState(false)
      this.clearNotifications()
      this.$router.push({ name: 'DataLoader', params: { id: event } })
    }
  }
})
