import { Commit, Dispatch, Getter } from 'vuex'
import { apiRequest } from '@/store/utils'
import {
  IRfSensor,
  IRfSensorListResponse,
  IRfSensorResponse,
  IRfSensorState,
  IRfSensorDisruptorCommand
} from '@/store/modules/rf_sensors/types'

export default {
  CLEAR_STATUS: ({ commit }: { commit: Commit }) => {
    commit('SET_STATUS', null)
    commit('SET_ERROR', null)
  },
  FETCH_RF_SENSORS: async ({ commit }: { commit: Commit }, siteId: number) => {
    await apiRequest(
      'get',
      `/api/sites/${siteId}/rf_sensors`,
      {},
      (data: IRfSensorListResponse) => {
        commit('LOAD_RF_SENSORS', data.rf_sensors)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  CREATE_RF_SENSOR: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: { siteId: number; rf_sensor: IRfSensor; dsx: boolean }
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'post',
      `/api/sites/${args.siteId}/rf_sensors`,
      { rf_sensor: args.rf_sensor },
      (data: IRfSensorResponse, _: any) => {
        commit('SET_STATUS', 'OK')
        commit(args.dsx ? 'ADD_DSX_SENSOR' : 'ADD_RF_SENSOR', data.rf_sensor)
        dispatch('SELECT_RF_SENSOR', data.rf_sensor.id)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  toggleDsxDisruptor: async (
    { state, dispatch }: { state: IRfSensorState, dispatch: Dispatch },
    args: { siteId: number, sensorId: number, command: IRfSensorDisruptorCommand }
  ) => {
    const sensor = state.dsxSensorsSet[args.sensorId]
    let command = args.command
    if (command === undefined) command = !sensor.jamming
    const payload = {
      siteId: args.siteId,
      rf_sensor: { ...sensor, power_trigger_command: command },
      dsx: true
    }
    const result = await dispatch('UPDATE_RF_SENSOR', payload)
    return result
  },
  toggleAllDsxDisruptors: async (
    { state, dispatch }: { state: IRfSensorState, dispatch: Dispatch },
    args: { siteId: number, command: IRfSensorDisruptorCommand }
  ) => {
    const sensors = Object.values(state.dsxSensorsSet)
    Promise.all(
      sensors.map(sensor => {
        let command = args.command
        if (command === undefined) command = !sensor.jamming
        const payload = {
          siteId: args.siteId,
          rf_sensor: { ...sensor, power_trigger_command: command },
          dsx: true
        }
        dispatch('UPDATE_RF_SENSOR', payload)
      })
    )
  },
  UPDATE_RF_SENSOR: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: { siteId: number; rf_sensor: IRfSensor; dsx: boolean }
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'put',
      `/api/sites/${args.siteId}/rf_sensors/${args.rf_sensor.id}`,
      { rf_sensor: args.rf_sensor },
      (data: IRfSensorResponse, _: any) => {
        commit('SET_STATUS', 'OK')
        commit(args.dsx ? 'ADD_DSX_SENSOR' : 'ADD_RF_SENSOR', data.rf_sensor)
        dispatch('SELECT_RF_SENSOR', data.rf_sensor.id)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  TOGGLE_RF_SENSOR: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: { siteId: number; rf_sensor: IRfSensor; dsx: boolean }
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'patch',
      `/api/sites/${args.siteId}/rf_sensors/${args.rf_sensor.id}`,
      { rf_sensor: { active: args.rf_sensor.active} },
      (data: IRfSensorResponse, _: any) => {
        commit('SET_STATUS', 'OK')
        commit(args.dsx ? 'ADD_DSX_SENSOR' : 'ADD_RF_SENSOR', data.rf_sensor)
        dispatch('SELECT_RF_SENSOR', data.rf_sensor.id)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  DELETE_RF_SENSOR: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: { siteId: number; rf_sensor: IRfSensor; dsx: boolean }
  ) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'delete',
      `/api/sites/${args.siteId}/rf_sensors/${args.rf_sensor.id}`,
      {},
      (data, _) => {
        commit(
          args.dsx ? 'REMOVE_DSX_SENSOR' : 'REMOVE_RF_SENSOR',
          args.rf_sensor.id
        )
        commit('SET_STATUS', 'OK')
        dispatch('UNSELECT_RF_SENSOR')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  SELECT_RF_SENSOR: ({ commit }: { commit: Commit }, id: number) => {
    commit('ACTIVATE_RF_SENSOR', id)
  },
  UNSELECT_RF_SENSOR: (
    { commit, state }: { commit: Commit; state: IRfSensorState },
    id: number
  ) => {
    if (!state.activeRfSensorId) return
    commit('DEACTIVATE_RF_SENSOR', id)
  },
  SOCKET_RF_SENSORS_UPDATE: (
    { commit }: { commit: Commit },
    sensors: IRfSensor[]
  ) => {
    sensors.forEach(sensor =>
      commit(
        sensor.model.includes('dsx') ? 'ADD_DSX_SENSOR' : 'ADD_RF_SENSOR',
        sensor
      )
    )
  },
}
