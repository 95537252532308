<template>
  <v-app dark :style="cssVariables">
    <template>
      <IdleListener :path="currentView.path" />
      <GlobalSnackbar />
      <error-box-dialog />
      <snackbar-hint />
      <template v-if="isAuthenticated">
        <left-nav
          v-show="isAuthenticated"
          :user="user"
          :nav-items="navigation"
        />
        <top-nav :status="status" v-show="isAuthenticated" :user="user" />
        <disconnection-warning v-if="!connectionStatus" />
        <latency-warning v-if="connectionStatus && latencyWarning" />
        <change-user-password-dialog
          :dialog="dialog === 'modifyProfile'"
          @onClose="dialog = null"
        />

        <logout-dialog :dialog="dialog === 'logout'" @onClose="dialog = null" />
      </template>
      <licence-dialog :dialog="dialog === 'licence'" @onClose="dialog = null" />
    </template>
    <v-content app>
      <router-view :active-site="`active-site-${activeSiteId}`"></router-view>
    </v-content>
  </v-app>
</template>

<script>
import { TopNav, LeftNav } from '@/components/Nav'
import { mapGetters, mapActions, mapState } from 'vuex'
const DisconnectionWarning = () => import('./components/Nav/DisconnectionAlert')
const LatencyWarning = () => import('./components/Nav/LatencyWarning')
const ChangeUserPasswordDialog = () =>
  import('./components/Dialogs/ChangeUserPassword')
const ErrorBoxDialog = () => import('./components/Dialogs/ErrorBox')
const LogoutDialog = () => import('./components/Dialogs/Logout')
const LicenceDialog = () => import('./components/Dialogs/Licence')
const SnackbarHint = () => import('./components/Dialogs/SnackbarHint')
import { SiteWarningsMixin } from '@/components/Mixins'
import { xor } from 'lodash'
import GlobalSnackbar from '@/components/Widgets/GlobalSnackbar'
import IdleListener from '@/components/Widgets/IdleListener.vue'

export default {
  name: 'MainApp',
  mixins: [SiteWarningsMixin],
  components: {
    IdleListener,
    GlobalSnackbar,
    ChangeUserPasswordDialog,
    ErrorBoxDialog,
    DisconnectionWarning,
    TopNav,
    LeftNav,
    LogoutDialog,
    LicenceDialog,
    SnackbarHint,
    LatencyWarning
  },
  data: () => ({
    dialog: null,
    rfFilterParams: {
      dialog: false
    },
    jetsonTemp: 0,
    radarMaskTotal: 0,
    snackbarTimeout: null,
    unsubscribe: null
  }),
  computed: {
    ...mapState(['status', 'connectionStatus', 'latencyWarning']),
    ...mapState('system', {
      rubySocketUrl: 'rubySocketUrl',
      coreSocketUrl: 'coreSocketUrl',
      environment: 'environment',
      simulation_enabled: 'simulation_enabled',
      dataLoaded: 'dataLoaded',
    }),
    ...mapGetters('system', ['isSocketConnected', 'isAlertsSocketConnected']),
    ...mapState('users', ['user']),
    ...mapState(['systemStats']),
    ...mapGetters('users', ['navItems']),
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('sites', ['activeSite', 'sitesSet', 'sitesLength']),
    ...mapGetters('auth', [
      'isAuthenticated',
      'token',
      'sessionId',
      'hasTokenExpired'
    ]),
    ...mapGetters('sensors', ['allSensorsInSentry']),
    ...mapGetters('cameras', ['janusCameraIds', 'getCoturnCred']),
    ...mapState('notifications', ['alerts']),
    ...mapState('radars', ['radarsSet']),
    ...mapState('cannons', ['cannonsSet']),
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('maps', ['uploadingMap', 'uploadFileId']),
    firstSiteId() {
      return Object.keys(this.sitesSet || {})[0]
    },
    navigation() {
      return this.sitesLength
        ? this.navItems
        : this.navItems.filter(item => ['/', '/plans'].includes(item.link))
    },
    currentView() {
      return this.$route
    },
    cssVariables() {
      const colors = []
      Object.keys(this.$vuetify.theme).forEach(key => {
        colors.push(`--dro-${key}: ${this.$vuetify.theme[key]}`)
      })
      return colors.join(';')
    }
  },
  timers: {
    checkWarnings: { time: 5000, autostart: true, repeat: true }
  },
  methods: {
    ...mapActions('sites', { activateSite: 'ACTIVATE_SITE' }),
    ...mapActions('zones', { fetchZones: 'FETCH_ZONES' }),
    ...mapActions('site_warnings', ['FETCH_SITE_WARNINGS']),
    ...mapActions('site_markers', ['FETCH_SITE_MARKERS']),
    ...mapActions('agl_mask', ['fetchAGLMaskTotal']),
    ...mapActions('sensors', ['FETCH_ZMASK_TOTAL']),
    ...mapActions('sensors', {
      unselectAll: 'UNSELECT_ALL',
      updateIdentities: 'UPDATE_IDENTITIES'
    }),
    ...mapActions('auth', ['AUTH_LOGOUT']),
    ...mapActions('maps', [
      'setActiveMapLayers',
      'setMapLayer',
      'addSiteMapLayerAssociation',
      'fetchSiteAndLayerMapping',
      'selectMapLayer',
      'setActiveMapLayer',
      'setUploadFileId',
      'setUploadingMapState',
      'cancelOfflineMapUploadSync'
    ]),
    ...mapActions('system', ['FETCH_SYSTEM_SETTINGS']),
    ...mapActions('snackbar', ['showSnackbar', 'closeSnackbar']),
    ...mapActions('system', [
      'setDataLoadedState',
      'setDataWebsocket',
      'setAlertsWebsocket'
    ]),
    ...mapActions('detection', {
      addDetection: 'addDetection'
    }),
    ...mapActions('system', ['FETCH_SYSTEM_INFO']),
    ...mapActions('cameras', ['FETCH_COTURN_CRED']),
    async fetchSystemInfo() {
      await this.FETCH_SYSTEM_INFO()
    },
    teardownEventListeners() {
      this.$bus.$off('addFilter')
      this.$bus.$off('SOCKET/OPEN')
      this.$bus.$off('SOCKET/CLOSE')
      this.$bus.$off('SOCKET/SENSOR_FUSION_DETECTION_CREATE')

      const namespaces = [
        'SITES',
        'SENTRIES',
        'RADARS',
        'RF_SENSORS',
        'CANNONS',
        'GPS_COMPASSES',
        'CAMERAS',
        'DISCOVAIR_SENSORS',
        'RADAR_PERF_STATS',
        'NOTES',
        'DRONE_MCU_UNITS',
        'SITE_WARNINGS',
        'REAL_DRONE',
        'SITE_MARKERS',
        'SIMULATION'
      ]

      namespaces.forEach(name => {
        this.$bus.$off(`SOCKET/${name}_UPDATE`)
        this.$bus.$off(`SOCKET/${name}_CREATE`)
        this.$bus.$off(`SOCKET/${name}_DELETE`)
      })

      this.$bus.$off(`SOCKET/RF_SENSORS_CREATE`)
      this.$bus.$off(`SOCKET/RF_SENSORS_UPDATE`)
      this.$bus.$off(`SOCKET/RF_SENSORS_DELETE`)

      this.$bus.$off(`SOCKET/CANNONS_CREATE`)
      this.$bus.$off(`SOCKET/CANNONS_UPDATE`)
      this.$bus.$off(`SOCKET/CANNONS_DELETE`)

      this.$bus.$off(`SOCKET/RADARS_CREATE`)
      this.$bus.$off(`SOCKET/RADARS_UPDATE`)
      this.$bus.$off(`SOCKET/RADARS_DELETE`)

      this.$bus.$off(`SOCKET/ZONE_EVENT_INTRUSIONS_CREATE`)
      this.$bus.$off(`SOCKET/ZONE_RF_EVENT_INTRUSIONS_CREATE`)
      this.$bus.$off('SOCKET/IDENTITY')

      this.$bus.$off('ALERTS/OPEN')
      this.$bus.$off('ALERTS/CLOSE')
      this.$bus.$off('ALERTS/MAP_LAYERS_CREATE')

      this.$bus.$off('openDialog')
      this.$bus.$off('app-logout')

      this.$bus.$off('forceLogout')
    },
    cancelMapUpload() {
      this.cancelOfflineMapUploadSync({
        fileId: this.uploadFileId,
        token: this.token
      })
    },
    async logOut() {
      await this.AUTH_LOGOUT()
      // to show branding logo after logout since state gets cleaned up
      await this.fetchSystemInfo()
    },
    showAlertNotification() {
      if (this.currentView.path !== '/' && this.isAuthenticated) {
        this.$bus.$emit('SNACKBAR/ALERTS')
      }
    },
    async fetchRadarZoneMasks() {
      return this.FETCH_ZMASK_TOTAL(this.activeSiteId)
    },
    async fetchAGLMasks(siteId) {
      await this.fetchAGLMaskTotal(siteId)
    },
    async init() {
      try {
        this.$store.commit('setStatus', null)
        if (this.status !== 'ERROR') {
          this.$store.commit('setStatus', 'LOADING')
        }
        this.$bus.$on(
          'addFilter',
          data => (this.rfFilterParams = { ...data, dialog: true })
        )
        if (this.janusCameraIds.length > 0) {
          let cred = await this.FETCH_COTURN_CRED()
          this.$janus.connect(this.janusCameraIds, cred.username, cred.password)
        }
      } catch (err) {
        this.$store.commit('setStatus', 'ERROR')
        this.$store.commit('setError', err.message)
        await this.AUTH_LOGOUT()
      }
    },
    initSocket() {
      this.$bus.$on('SOCKET/OPEN', () => {
        this.$store.commit('system/UPDATE_SOCKET', { isConnected: true })
      })

      this.$bus.$on('SOCKET/CLOSE', () => {
        this.$store.commit('system/UPDATE_SOCKET', { isConnected: false })
      })

      this.$bus.$on(`SOCKET/SENSOR_FUSION_DETECTION_CREATE`, payload => {
        const { data } = payload.message
        this.addDetection(data)
      })

      const namespaces = [
        'SITES',
        'SENTRIES',
        'RADARS',
        'RF_SENSORS',
        'CANNONS',
        'GPS_COMPASSES',
        'CAMERAS',
        'DISCOVAIR_SENSORS',
        'RADAR_PERF_STATS',
        'NOTES',
        'DRONE_MCU_UNITS',
        'SITE_WARNINGS',
        'REAL_DRONE',
        'SITE_MARKERS',
        'SIMULATION'
      ]

      namespaces.forEach(name => {
        let namespace
        switch (name) {
          case 'SITES':
            namespace = 'sites'
            break
          case 'SENTRIES':
            namespace = 'sentries'
            break
          case 'RADAR_PERF_STATS':
            namespace = 'radar_perf_stats'
            break
          case 'NOTES':
            namespace = 'notes'
            break
          case 'DRONE_MCU_UNITS':
            namespace = 'drone_mcu_units'
            break
          case 'SITE_WARNINGS':
            namespace = 'site_warnings'
            break
          case 'REAL_DRONE':
            namespace = 'real_drone'
            break
          case 'SIMULATION':
            namespace = 'simulation'
            break
          case 'SITE_MARKERS':
            namespace = 'site_markers'
            break
          case 'CAMERAS':
            namespace = 'cameras'
            break
          case 'RF_SENSORS':
            namespace = 'rf_sensors'
            break
          case 'CANNONS':
            namespace = 'cannons'
            break
          case 'RADARS':
            namespace = 'radars'
            break
          default:
            namespace = 'sensors'
        }

        this.$bus.$on(`SOCKET/${name}_UPDATE`, payload => {
          if (payload) {
            const { data } = payload.message
            this.$store.dispatch(`${namespace}/SOCKET_${name}_UPDATE`, data)
          }
        })

        // listens to create events, if there's a create event then fire mutation to add data to vuex store.
        this.$bus.$on(`SOCKET/${name}_CREATE`, payload => {
          const { data } = payload.message
          return this.$store.commit(`${namespace}/SOCKET_${name}_CREATE`, data)
        })
        // listens to delete events, if there's a delete event then fire mutation to add data to vuex store.
        this.$bus.$on(`SOCKET/${name}_DELETE`, payload => {
          const { data } = payload.message
          return this.$store.commit(`${namespace}/SOCKET_${name}_DELETE`, data)
        })
      })

      this.$bus.$on(`SOCKET/COMMANDS_UPDATE`, payload => {
        const command = payload?.message?.data[0]
        if (command.id) {
          return this.$store.dispatch(
            `drone_mcu_units/SOCKET_DRONE_COMMAND_UPDATE`,
            command
          )
        }
      })

      this.$bus.$on(`SOCKET/ZONE_EVENT_INTRUSIONS_CREATE`, payload => {
        const { data } = payload.message
        data[0].key = data[0].track?.id
        return this.$store.commit('notifications/ADD_ALERT', data[0])
      })

      this.$bus.$on('SOCKET/IDENTITY', ({ message }) => {
        this.updateIdentities(message)
      })

      this.$bus.$on(`SOCKET/SITES_PARTIAL_UPDATE`, payload => {
        if (payload) {
          const { data } = payload.message
          this.$store.dispatch(`sites/SOCKET_SITES_PARTIAL_UPDATE`, data)
        }
      })

      const url = `${this.rubySocketUrl}?auth_token=${this.token}`
    },
    initAlertSocket() {
      this.$bus.$on('ALERTS/OPEN', () => {
        this.$store.commit('system/UPDATE_ALERTS_SOCKET', { isConnected: true })
      })

      this.$bus.$on('ALERTS/CLOSE', () => {
        this.$store.commit('system/UPDATE_ALERTS_SOCKET', {
          isConnected: false
        })
      })

      this.$bus.$on('ALERTS/MAP_LAYERS_CREATE', async payload => {
        // TODO: remove this once core-api is the socket server so the credentials are correct
        if (!this.isAuthorized('site_manager')) return

        if (payload.message.type === 'error') {
          this.$bus.$emit('APP_MESSAGE', {
            flush: 'manual',
            color: 'error',
            title: 'Map upload failed',
            message: `Reason: ${payload.message.data.message}`
          })
          await this.setUploadingMapState(false)
        } else if (payload.message.type === 'info') {
          await this.showSnackbar({
            snackbarText: payload.message.data.message,
            snackbarTimeout: 5000
          })
        } else {
          await this.setUploadingMapState(false)
          await this.showSnackbar({
            snackbarText: payload.message.data.message,
            snackbarTimeout: 5000
          })
          await this.$store.dispatch('maps/fetchAllMapLayers')

          if (
            payload.message.action === 'create' &&
            payload.message.type === 'success'
          ) {
            const newMapLayer = payload.message.data.mapLayer
            await this.addSiteMapLayerAssociation({
              siteId: this.activeSiteId,
              layerId: newMapLayer.id
            })
            await this.fetchSiteAndLayerMapping(this.activeSiteId)
            this.selectMapLayer(newMapLayer)
            await this.setActiveMapLayer(newMapLayer.id)
            await this.setUploadFileId(null)
          }
        }
      })
    }
  },
  watch: {
    dataLoaded: {
      handler: function(val) {
        if (val) {
          this.init()
        }
      }
    },
    uploadingMap: {
      handler: function(val) {
        if (val) {
          window.onunload = this.cancelMapUpload
        } else {
          window.onunload = () => {}
        }
      },
      immediate: true
    },
    async activeSiteId(v, o) {
      if (v) {
        this.unselectAll()
      }
    },
    alerts: {
      handler: function(newVal, oldVal) {
        const diff = xor(newVal, oldVal)
        if (diff.length) {
          this.showAlertNotification()
        }
      }
    },
    isAuthenticated(value) {
      if (!value) {
        this.$router.push('/login')
      }
    }
  },
  async created() {
    this.$bus.$on('openDialog', data => {
      this.dialog = data
    })
    this.$bus.$on('app-logout', this.logOut)

    // Check for token expiry date based on the token stored in local storage
    // also using a try/catch condition for any possible problem with the token data at the parsing stage
    try {
      if (this.isAuthenticated) {
        if (this.hasTokenExpired) {
          return this.AUTH_LOGOUT()
        }
      }
    } catch (err) {
      this.$store.commit('setStatus', 'ERROR')
      this.$store.commit('setError', err.message)
    }
  },
  beforeDestroy: function() {
    this.teardownEventListeners()
    clearInterval(this.interval)
    this.setDataLoadedState(false)
    // C2-6188 Push selected detection to the socket:
    this.unsubscribe()
  },
  mounted() {
    this.fetchSystemInfo()
    this.$bus.$on('forceLogout', () => {
      this.AUTH_LOGOUT()
    })
    // C2-6188 Push selected detection to the socket:
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type.match(
          /^detection\/(SELECT_DETECTION|CLEAR_SELECTED_DETECTIONS)$/
        )
      ) {
        const websocket = state.system.dataWebsocket.socket
        const command = 'message'
        const identifier = JSON.stringify({
          channel: 'SitesChannel',
          site_id: state.sites.activeSiteId
        })
        let message
        if (mutation.type === 'detection/CLEAR_SELECTED_DETECTIONS') {
          message = {
            action: 'delete',
            space: 'selected_detection',
            site_id: state.sites.activeSiteId
          }
        } else if (mutation.type === 'detection/SELECT_DETECTION') {
          const detection = mutation.payload
          message = {
            action: 'create',
            space: 'selected_detection',
            site_id: state.sites.activeSiteId,
            data: {
              target_id: detection
            }
          }
        }
        if (websocket.readyState === 1) {
          websocket.send(
            JSON.stringify({
              command: command,
              identifier: identifier,
              data: JSON.stringify(message)
            })
          )
        }
      }
    })
    window.onbeforeunload = () => {
      if (this.uploadingMap) {
        // return something to show the browser prompt
        return ''
      } else {
        return undefined
      }
    }
  }
}
</script>

<style>
html {
  overflow-y: auto;
}

/* firefox mis alignmend fix */
.v-icon {
  display: inline-flex !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: var(--dro-primary);
}

::-webkit-scrollbar-track-piece {
  background-color: #262626;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: var(--dro-primary);
  border-radius: 0px;
}

::-webkit-scrollbar-corner {
  background-color: var(--dro-primary);
}

::-webkit-resizer {
  background-color: var(--dro-primary);
}

.sensorForm {
  height: 100%;
  overflow-y: auto;
  background-color: #424242;
}

.v-badge--overlap .v-badge__badge {
  top: -2px;
  right: -2px;
  height: 8px;
  width: 8px;
}

.v-overlay--active:before {
  opacity: 0.75;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#awesomeCamera { 
  cursor: initial;
  -webkit-cursor: initial;
}

#awesomeCamera .back {
  pointer-events: none;
}

#awesomeCamera .front {
  pointer-events: all;
  cursor: grab;
}

</style>
