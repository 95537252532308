import '@babel/polyfill'
import Vue, { VNode } from 'vue'
import './plugins/vuetify'

import mitt from 'mitt'
import { ObserveVisibility } from 'vue-observe-visibility'
Vue.directive('observe-visibility', ObserveVisibility)

// @ts-ignore
import PolygonFillpattern from 'vue2-leaflet-polygonfillpattern'

// DroneShield base components
import {
  DSubmit,
  DConfirm,
  DConfirmDialog,
  DParamSlider,
  DView,
  DForm
  // @ts-ignore
} from '@/components/Base'
Vue.component('d-submit', DSubmit)
Vue.component('d-form', DForm)
Vue.component('d-confirm', DConfirm)
Vue.component('d-confirm-dialog', DConfirmDialog)
Vue.component('d-param-slider', DParamSlider)
Vue.component('d-view', DView)

Vue.use(PolygonFillpattern)
// @ts-ignore
import VueTimers from 'vue-timers'
Vue.use(VueTimers)

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import './plugins/vuetify'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'

import 'leaflet/dist/leaflet.css'
// @ts-ignore
import L from 'leaflet'
import 'leaflet-editable'

import 'vue-phone-number-input/dist/vue-phone-number-input.css'

import './plugins/dragPath'
// @ts-ignore
import { DirectiveBinding } from 'vue/types/options'

const isProduction = process.env.NODE_ENV === 'production'

Vue.config.productionTip = !isProduction
Vue.config.devtools = !(process.env.VUE_APP_DISABLE_DEV_TOOL === "true")
Vue.config.performance = !isProduction
Vue.config.silent = isProduction
// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

// Adding a event bus to the app, as using the state manager to call a function through the state itself could be messy and end up with over coding
export const EventBus = new Vue()

// Adding the event bus as a VUE prototype element as is only necessary one instance, like singleton pattern
Object.defineProperties(Vue.prototype, {
  $bus: {
    get: () => EventBus
  }
})

const emitter = mitt()
Object.defineProperties(Vue.prototype, {
  $emitter: {
    get: () => emitter
  }
})

const update = (
  el: HTMLElement,
  binding: DirectiveBinding,
  vnode: VNode,
  oldVnode: VNode
) => (el.style.visibility = binding.value ? 'hidden' : '')
Vue.directive('hide', {
  bind: update,
  update: update
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
