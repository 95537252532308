import { IRadarState, IRadar } from '@/store/modules/radars/types'

export default {
  SET_STATUS: (state: IRadarState, status: string) => (state.status = status),
  SET_ERROR: (state: IRadarState, error: any) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  LOAD_RADARS: (state: IRadarState, radars: IRadar[]) => {
    radars?.forEach((r: IRadar) => {
      state.radarsSet[r.id] = r
    })
  },
  ADD_RADAR: (state: IRadarState, radar: IRadar) => {
    const update = Object.assign({}, state.radarsSet)
    update[radar.id] = radar
    state.radarsSet = update
  },
  REMOVE_RADAR: (state: IRadarState, id: number) => {
    if (!id) return
    const update = Object.assign({}, state.radarsSet)
    delete update[id]
    state.radarsSet = update
    // Prevent icon to remain on map after deletion
    if (state.activeRadarId === id) {
      state.activeRadarId = null
    }
  },
  ACTIVATE_RADAR: (state: IRadarState, id: number) => {
    state.activeRadarId = id
  },
  DEACTIVATE_RADAR: (state: IRadarState) => {
    state.activeRadarId = null
  },
  SET_SCOPED_ERROR_RADAR: (state: IRadarState, args: { id: number, error: any }) => {
    state.errorByRadar[args.id] = args.error
  },
  SOCKET_RADARS_CREATE: (state: IRadarState, sensors: IRadar[]) => {
    return sensors.forEach(sensor => {
      state['radarsSet'] = Object.assign({}, state['radarsSet'], {
        [sensor.id]: sensor
      })
    })
  },
  SOCKET_RADARS_DELETE: (state: IRadarState, sensors: IRadar[]) => {
    return sensors.forEach(sensor => {
      const { id } = sensor
      if (!id) return
      const update = Object.assign({}, state['radarsSet'])
      delete update[id]
      state['radarsSet'] = update
      // Prevent icon to remain on map after deletion
      if (state.activeRadarId === id) {
        state.activeRadarId = null
      }
    })
  }
}
