import { ISentry } from '@/store/modules/sentries/types'

export interface IRfSensor {
  id: number
  serial_number: string
  angle: number
  label: string
  reach: number
  name: string
  sentry_id: number
  show_sectors: boolean
  show_sector_as_line: boolean
  state_orig: string
  status_orig: string
  direction_offset: number
  tilt: number
  status_message: string
  model: EModel
  show_outside_detections: boolean
  software_version: string
  show_720_detection: boolean
  active: boolean
  temperature: number
  latitude: number
  longitude: number
  sentry_altitude: number
  direction: number
  altitude: number
  supported_detections: { [key: string]: any }
  state: string
  status: string
  status_color: string
  jamming: boolean
  band_24_58_trigger_engaged: boolean
  gnss_trigger_engaged: boolean
  reach_jamming: number
  auto_shutoff_time: number
}

export enum EModel {
  RfZeroString = 'rf_zero',
  RfOneString = 'rf_one',
  RfTwoString = 'rf_two',
  DsxDirectString = 'dsx_direct',
  DsxDirectNoJamString = 'dsx_direct_no_jam',
  RfPatrolString = 'rf_patrol'
}

export interface IRfSensorState {
  rfSensorsSet: IRfSensorsSet
  dsxSensorsSet: IRfSensorsSet
  activeRfSensorId: number
  error: string
  status: string
}

export interface IRfSensorsSet {
  [key: number]: IRfSensor
}

export interface IRfSensorResponse {
  rf_sensor: IRfSensor
}

export type IRfSensorDisruptorCommand = true | false | undefined

export interface IRfSensorListResponse {
  rf_sensors: IRfSensor[]
}

export const DsxSensorTypes = [
  {
    value: 'dsx_direct',
    text: 'DroneSentry-X Mk1',
    directional: true,
    jammer: true
  },
  {
    value: 'dsx_direct_no_jam',
    text: 'DroneSentry-X Mk1 (Detect Only)',
    directional: true,
    jammer: false
  },
  {
    value: 'dsx_mk2_no_jam',
    text: 'DroneSentry-X Mk2 (Detect Only)',
    directional: true,
    jammer: false
  }
]

//for select input
export const RfSensorTypes = [
  { value: 'rf_two', text: 'RfOne Mk2' },
  { value: 'rf_patrol', text: 'RfPatrol Mk2' }
]

export const isDsxModelJamming = (model: string) => {
  const sensorType = DsxSensorTypes.find(s => s.value === model)
  if (sensorType?.jammer === true) return true
  return false
}

export const isDsxModelDirectional = (model: string) => {
  const sensorType = DsxSensorTypes.find(s => s.value === model)
  if (sensorType?.directional === true) return true
  return false
}

export const buildSensorObject = (
  site: number,
  sentries: ISentry[],
  sensorsSet: {}
): Record<string, IRfSensor> => {
  const siteSentryIds = sentries.map((sentry: ISentry) => sentry.id)
  const rfSensorObject = {}
  Object.values(sensorsSet).forEach((rfSensor: IRfSensor) => {
    if (siteSentryIds.includes(rfSensor.sentry_id)) {
      rfSensorObject[`${rfSensor.id}-${rfSensor.sentry_id}`] = rfSensor
    }
  })
  return rfSensorObject
}
