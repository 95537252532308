import Vue from 'vue'
import Vuex from 'vuex'
import { apiRequest, getPersistent, setPersistent } from './utils'
import auth from './modules/auth'
import sentries from './modules/sentries'
import sensors from './modules/sensors'
import reports from './modules/reports'
import zones from './modules/zones'
import maps from './modules/maps'
import users from './modules/users'
import plans from './modules/plans'
import radar_perf_stats from './modules/radar_perf_stats'
import cursor_on_target from './modules/cursor_on_target'
import warnings from './modules/warnings'
import selection from './modules/selection'
import notifications from './modules/notifications'
import rf_filters from './modules/rf_filters'
import rf_sensors from './modules/rf_sensors'
import recordings from './modules/recordings'
import radars from './modules/radars'

//Typescript modules
import drone_mcu_units from './modules/drone_mcu_units/index.ts'
import system from './modules/system/index.ts'
import real_drone from './modules/real_drone/index.ts'
import site_markers from './modules/site_markers'
import site_warnings from './modules/site_warnings'
import sites from './modules/sites'
import notes from './modules/notes'
import trackers from './modules/trackers'
import snackbar from '@/store/modules/snackbar'
import detection_labels from './modules/detection_labels'
import agl_mask from './modules/agl_mask'
import detection from './modules/detection'
import radar_mask_zones from './modules/radar_mask_zones'
import simulation from './modules/simulation/index.ts'
import cameras from './modules/cameras/index.ts'
import cannons from './modules/cannons/index.ts'

Vue.use(Vuex)

const modules = {
  auth,
  cursor_on_target,
  maps,
  plans,
  users,
  reports,
  sensors,
  sentries,
  sites,
  system,
  zones,
  radar_perf_stats,
  warnings,
  notes,
  drone_mcu_units,
  selection,
  site_warnings,
  notifications,
  real_drone,
  site_markers,
  trackers,
  snackbar,
  detection_labels,
  agl_mask,
  detection,
  radar_mask_zones,
  simulation,
  rf_filters,
  cameras,
  rf_sensors,
  cannons,
  recordings,
  radars
}

export default new Vuex.Store({
  modules,
  state: {
    radiatingCircle: [],
    displayedSectors: {},
    mgrsEnabled: false,
    milsEnabled: false,
    theme: getPersistent('theme', 'DroneShield'),
    mapLayer: 'ESRI World Imagery Satellite',
    timezonesList: [],
    systemStats: {
      CPUPercentage: null,
      RAMPercentage: null,
      Uptime: null
    },
    status: null,
    error: null,
    activeGroup: {
      radars: false,
      rf_sensors: false,
      gps_compasses: false,
      cannons: false,
      cameras: false,
      discovair_sensors: false,
      dsx_sensors: false
    },
    offline: false,
    mapScreenshot: '',
    pdfPlanPricing: { year1: [], year2: [] },
    connectionStatus: true,
    latencyWarning: false,
    dialogs: {
      rfFilterForm: false
    },
    formData: {
      rfFilterForm: null
    }
  },
  getters: {
    getDegreeUnit: state => (state.milsEnabled ? 'mil' : 'deg'),
    displayedSectors: state => state.displayedSectors,
    displayedSectorsInSite: state => activeSiteId =>
      state.displayedSectors[activeSiteId] || [],
    isRfFilterFormDialogShown: state => state.dialogs.rfFilterForm,
    rfFilterFormDialogData: state => state.formData.rfFilterForm,
    getRadiatingCircle: state => state.radiatingCircle,
  },
  mutations: {
    setMapScreenshot: (state, img) => (state.mapScreenshot = img),
    setPdfPlanPricing: (state, pricing) => (state.pdfPlanPricing = pricing),
    SET_RADIATING_CIRCLE: (state, v) => {
      state.radiatingCircle = v
    },
    setMgrs: (state, enabled) => {
      state.mgrsEnabled = enabled
    },
    setMils: (state, enabled) => {
      state.milsEnabled = enabled
    },
    setDisplayedSectors: (state, v) => {
      state.displayedSectors = Object.assign({}, v)
    },
    setDisplayedSectorsBySiteId: (state, v) => {
      state.displayedSectors[v.site_id] = v.data
    },
    setActiveGroup: (state, v) => (state.activeGroup = v),
    setStatus: (state, s) => (state.status = s),
    setSystemStats: (state, s) => (state.systemStats = s),
    setError: (state, err) => (state.error = err),
    setOffline: (state, value) => (state.offline = value),
    setTimezonesList: (state, values) => (state.timezonesList = values),
    setConnectionStatus: (state, v) => (state.connectionStatus = v),
    setTheme: (state, v) => {
      state.theme = v.name
    },
    SET_RF_FILTER_FORM_DIALOG_STATE: (state, status) => {
      state.dialogs.rfFilterForm = status
    },
    SET_RF_FILTER_FORM_DIALOG_DATA: (state, data) => {
      state.formData.rfFilterForm = data
    },
    SET_DISCONNECTION_STATUS: (state, data) => {
      state.connectionStatus = data
    },
    SET_LATENCY_WARNING_STATUS: (state, data) => {
      state.latencyWarning = data
    }
  },
  actions: {
    _CLEAR_DATA: ({ commit }) => {
      localStorage.clear()
      sessionStorage.clear()
      Object.keys(modules).forEach(module => commit(`${module}/resetState`))
    },
    _CLEAR_SENSORS: ({ commit }) => {
      ['rf_sensors', 'radars', 'cameras'].forEach(module => commit(`${module}/resetState`))
    },
    FETCH_TIMEZONES: async ({ commit }) => {
      await apiRequest(
        'get',
        `/timezones.json`,
        {},
        data => {
          commit('setTimezonesList', data)
        },
        (data, _) => {
          commit('setStatus', 'FAILED')
          commit('setError', data.error)
        }
      )
    },
    SET_ACTIVE_GROUP: ({ commit, dispatch }, group) => {
      commit('setActiveGroup', group)
      dispatch('sensors/selectUniqueSensorForGroup', Object.keys(group)[0], {
        root: true
      })
    },
    SET_THEME: ({ commit }, theme) => {
      commit('setTheme', theme)
    },
    showRfFilterFormDialog: ({ commit }) => {
      commit('SET_RF_FILTER_FORM_DIALOG_STATE', true)
    },
    hideRfFilterFormDialog: ({ commit }) => {
      commit('SET_RF_FILTER_FORM_DIALOG_STATE', false)
    },
    setRfFilterFormDialogData: async ({ commit }, data) => {
      commit('SET_RF_FILTER_FORM_DIALOG_DATA', data)
    },
    setRadiatingCircle: ({ commit }, data) => {
      commit('SET_RADIATING_CIRCLE', data)
    }
  }
})
