import { Commit, Dispatch } from 'vuex'
import { apiRequest } from '../../utils'
import {
  ICannon,
  ICannonPatchResponse,
  ICannonResponse
} from '@/store/modules/cannons/types'

export default {
  FETCH_CANNONS: async ({ commit, rootState }) => {
    const { activeSiteId } = rootState.sites || {}
    await apiRequest(
      'get',
      `api/sites/${activeSiteId}/cannons`,
      { body: {} },
      data => {
        commit(`LOAD_CANNONS`, data)
      },
      (data, _) => {
        commit(`LOAD_CANNONS`, [])
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  CREATE_CANNON: async (
    { commit, dispatch },
    args: { siteId: number; cannon: ICannon }
  ) => {
    const { siteId, cannon } = args
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'post',
      `/api/sites/${siteId}/cannons`,
      { ...cannon, shutoff_time: Number(cannon.shutoff_time) },
      (data: ICannonResponse, _) => {
        commit('SET_STATUS', 'OK')
        if (data && data.cannon.id) {
          dispatch('SELECT_CANNON', data.cannon.id)
        }
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  UPDATE_CANNON: async (
    { commit },
    args: { siteId: number; cannon: ICannon }
  ) => {
    const { siteId, cannon } = args
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'put',
      `api/sites/${siteId}/cannons/${cannon?.id}`,
      { ...cannon },
      data => {
        commit('SET_STATUS', 'OK')
        // commit(`UPDATE_CANNON`, data)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  PATCH_CANNON: async (
    { commit }: { commit: Commit },
    args: { siteId: number; sensorId: number; response: ICannonPatchResponse }
  ) => {
    const { siteId, sensorId, response } = args
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'patch',
      `api/sites/${siteId}/cannons/${sensorId}`,
      { ...response },
      data => {
        commit('SET_STATUS', 'OK')
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  DELETE_CANNON: async (
    { commit, dispatch },
    args: { siteId: number; cannon: ICannon }
  ) => {
    const { siteId, cannon } = args
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'delete',
      `/api/sites/${siteId}/cannons/${cannon?.id}`,
      { cannon },
      (data, _) => {
        commit('SET_STATUS', 'OK')
        commit('SOCKET_CANNONS_DELETE')
        dispatch('UNSELECT_CANNON')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },

  CLEAR_STATUS: ({ commit }: { commit: Commit }) => {
    commit('SET_STATUS', null)
    commit('SET_ERROR', null)
  },

  toggleDisruptor: async (
    { state, dispatch },
    { siteId, sensorId, command = undefined }
  ) => {
    const sensor = state.cannonsSet[sensorId]
    if (command === undefined) command = !sensor.power_trigger_engaged
    await dispatch('PATCH_CANNON', {
      siteId,
      sensorId,
      response: {
        power_trigger_command: command
      }
    })
  },
  toggleAllDisruptors: async (
    { state, dispatch },
    { siteId, command = undefined }
  ) => {
    const cannons = Object.values(state.cannonsSet).filter(
      (cannon: ICannon) =>
        !!cannon.gnss_trigger_engaged ||
        !!cannon.band_24_58_trigger_engaged ||
        !!cannon.band_433_trigger_engaged ||
        !!cannon.band_915_trigger_engaged ||
        !!cannon.band_433_915_trigger_engaged
    )
    await Promise.all(
      cannons.map((cannon: ICannon) => {
        if (command === undefined) command = !cannon.power_trigger_engaged
        dispatch('PATCH_CANNON', {
          siteId,
          sensorId: cannon.id,
          response: {
            power_trigger_command: command
          }
        })
      })
    )
  },
  TOGGLE_CANNON: async ({ commit }, { sensor, command, deviceType }) => {
    commit('SET_STATUS', 'LOADING')
    const dataCannon = {
      device_type: deviceType || 'cannon',
      device_id: sensor.id,
      command: command,
      is_manual: true
    }

    return await apiRequest(
      'post',
      '/commands.json',
      { command: dataCannon },
      data => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit(`SET_SCOPED_ERROR_CANNON`, {
          id: sensor.id,
          error: new Error(data.error)
        })
      }
    )
  },
  FETCH_DSX_JAMMERS: async ({ commit }, payload) => {
    return await apiRequest(
      'get',
      `api/sites/${payload.site_id}/cannons?only_dsx=true`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        return data.cannons
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },

  SOCKET_CANNONS_UPDATE: ({ commit }, updates) => {
    return updates.forEach(({ id, ...update }) => {
      commit('UPDATE_CANNON', [{ id, ...update }])
    })
  },

  SELECT_CANNON: ({ commit, getters }, id) => {
    if (getters.activeCannonId) {
      commit('ACTIVATE_CANNON', id)
    }
  },

  UNSELECT_CANNON: ({ commit, getters }) => {
    if (getters.activeCannonId) {
      commit('DEACTIVATE_CANNON')
    }
  },

  setHighlightedAllCannons: ({ commit }, state) => {
    commit('SET_HIGHLIGHTED_ALL_CANNONS', state)
  },
  setHighlightedCannon: ({ commit }, state) => {
    commit('SET_HIGHLIGHTED_CANNON', state)
  },

  setCannonQuickPanelState: ({ commit }, state) => {
    commit('SET_CANNON_QUICKPANEL_STATE', state)
  }
}
