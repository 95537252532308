import { apiRequest, axiosInstance } from '../../utils'
import {
  ISystemSetting,
  ISystemInfo
} from './types'
import omit from 'lodash/omit'

export default {
  FETCH_SYSTEM_INFO: async ({ commit }: { commit: any }) => {
    await apiRequest(
      'get',
      '/system_infos.json',
      {},
      (data: ISystemInfo) => {
        commit('LOAD_SYS_INFO', data)
        commit('setOffline', data.environment === 'prem', { root: true })
      },
      (data: any, _: any) => {
        console.log(`failed to pull system stats info because: ${data.error}`)
      }
    )
  },
  setDataLoadedState: ({ commit }: { commit: any }, status: boolean) => {
    commit('SET_DATA_LOADED_STATE', status)
  },
}
