import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { getPersistent } from '@/store/utils'

const getDefaultState = () => {
  return {
    cred: {
      admin: 0,
      manager: 1,
      site_manager: 2,
      operator: 3,
      guest: 4,
      unknown: 5
    },
    role_map: {
      site_manager: 1,
      operator: 2,
      guest: 3
    },
    client: null,
    clientsList: [],
    user: {},
    usersList: [],
    sitesAssociations: [],
    recipientsSet: {},
    navItems: [
      {
        icon: 'widgets',
        title: 'Planning Tool',
        cy: 'plans',
        link: '/',
        role: 'guest'
      }
    ],
    isDeveloper: getPersistent('isDeveloper', false),
    developerSettings: getPersistent('developerSettings', {
      showAllTrails: true,
      showDetectionDetails: true,
      doNotOpenCameraPopup: true
    }),
    status: null,
    error: null
  }
}

const state = getDefaultState()

const resetState = state => Object.assign(state, getDefaultState())

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
