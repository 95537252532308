import { apiRequest } from '../../utils'
import get from 'lodash/get'
import pick from 'lodash/pick'

export default {
  selectDetection: ({ commit }, key) => {
    return commit('activateDetection', key)
  },
  selectRFBeam: ({ commit }, key) => {
    return commit('activateRFBeam', key)
  },
  FETCH_RF_FILTER_DATA: async ({ commit }, payload) => {
    return await apiRequest(
      'get',
      `/rf_filter_data.json?site_id=${payload.site_id}`,
      {},
      _ => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  RESTART_SENSOR: async ({ commit, dispatch }, { sensor_type, site_id }) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'post',
      `/api/sites/${site_id}/restart_sensors`,
      { sensor_type: sensor_type },
      (data, _) => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit(`SET_ERROR`, new Error(data.error))
      }
    )
  },
  // Load Radar Masks
  FETCH_RMASK: async ({ commit }, radar) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'get',
      `/radars/${radar.id}/radar_masks.json`,
      {},
      data => {
        commit('SET_STATUS', 'OK')
        commit('SET_RMASK', { radar, mask: data })
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  UPDATE_RMASK: async ({ commit }, { radar, mask }) => {
    commit('SET_STATUS', 'LOADING')

    delete mask.created_at
    delete mask.updated_at
    delete mask.radar_id
    return await apiRequest(
      'patch',
      `/radars/${radar.id}/radar_masks/${mask.id}.json`,
      { radar_mask: mask },
      _ => {
        commit('SET_STATUS', 'OK')
        commit('UPDATE_RMASK', { radar, mask })
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  RF_PERF_ESTIMATION: async ({ commit }, rf_perf) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'post',
      `/rf_perf_estimations.json`,
      { rf_perf },
      data => {
        commit('SET_STATUS', 'OK')
        commit('UPDATE_RF_PERF_ESTIMATION', data)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        commit('UPDATE_RF_PERF_ESTIMATION', [])
      }
    )
  },

  UNSELECT_ALL: ({ dispatch }) => {
    dispatch('UNSELECT_RADAR')
    dispatch('UNSELECT_GPS_COMPASS')
    // TODO: somehow this is required
    // dispatch('UNSELECT_RF_SENSOR')
    // dispatch('UNSELECT_DSX_SENSOR')

    // Do not unselect camera
  },

  ACTIVE_SENSOR_ID: ({ dispatch }, sensorType = 'radar') => {
    return dispatch(`active${sensorType}Id`)
  },
  SELECT_SENSOR: ({ dispatch }, { id, sensorType = 'radar' }) => {
    return dispatch(`SELECT_${sensorType.toUpperCase()}`, id)
  },
  // TODO: review this func, it seems not really used anymore
  selectUniqueGroupForSentry: ({ dispatch, getters }, sentry) => {
    const autoUnfoldGroups = [
      // 'cannons',
      'radars'
      // 'rf_sensors',
      // 'dsx_sensors'
    ]
    const expandableGroups = autoUnfoldGroups.filter(
      k => getters['allSensorsInSentry'](sentry)[k].length > 0
    )

    if (expandableGroups.length === 1) {
      setTimeout(
        () =>
          dispatch(
            'SET_ACTIVE_GROUP',
            { [expandableGroups[0]]: true },
            { root: true }
          ),
        200
      )
    }
  },

  selectUniqueSensorForGroup: ({ dispatch, getters, rootGetters }, group) => {
    const sentry = rootGetters['sentries/activeSentry']
    const sensors = getters['allSensorsInSentry'](sentry)[group]
    if (sensors.length === 1) {
      setTimeout(
        () =>
          dispatch(
            `sensors/SELECT_${group.slice(0, -1).toUpperCase()}`,
            sensors[0].id,
            { root: true }
          ),
        400
      )
    }
  },
  
  setFilterRetroApplyStatus: ({ commit }, status) => {
    commit('SET_FILTER_RETRO_APPLY', status)
  },
  triggerFilterRetroApply: async ({ commit }, payload) => {
    return await apiRequest(
      'get',
      `/rf_sensors/${payload.sensor}/rf_filters/${payload.filter}/apply_retrospectively.json`,
      {},
      data => {
        commit('SET_STATUS', 'OK')
        return { data: data, status: 200 }
      },
      (data, status) => {
        return { data: data.error, status: status }
      }
    )
  },

  UPDATE_IDENTITIES: ({ commit }, identity) => {
    commit('UPDATE_IDENTITIES', identity)
  },
  CLEAR_IDENTITIES: ({ commit }) => {
    commit('CLEAR_IDENTITIES')
  }
}
