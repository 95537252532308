import {
  ICannon,
  ICannonListResponse,
  ICannonResponse,
  ICannonsState
} from './types'
import { IRfSensor, IRfSensorState } from '@/store/modules/rf_sensors/types'
import { ICamera, ICameraState } from '@/store/modules/cameras/types'

export default {
  SET_STATUS: (state: ICannonsState, status) => (state.status = status),
  SET_ERROR: (state: ICannonsState, error) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  LOAD_CANNONS: (state: ICannonsState, { cannons }) => {
    if (!cannons) {
      state.cannonsSet = {}
      return
    }
    state.cannonsSet = cannons.reduce((acc: ICannon[], curr: ICannon) => {
      return Object.assign(acc, { [curr.id]: curr })
    }, {})
  },
  UPDATE_CANNON: (state: ICannonsState, cannons: ICannon[]) => {
    if (cannons && !Array.isArray(cannons)) {
      cannons = [cannons]
    }
    return cannons.forEach(({ id, ...update }) =>
      id && state.cannonsSet && state.cannonsSet[id]
        ? Object.assign(state.cannonsSet[id], update)
        : null
    )
  },
  ACTIVATE_CANNON: (state: ICannonsState, id) => {
    state.activeCannonId = id
  },
  DEACTIVATE_CANNON: state => (state.activeCannonId = null),

  SOCKET_CANNONS_CREATE: (state: ICannonsState, cannons: ICannon[]) => {
    return cannons.forEach(cannon => {
      state.cannonsSet = Object.assign({}, state.cannonsSet, {
        [cannon.id]: cannon
      })
    })
  },
  SOCKET_CANNONS_DELETE: (state: ICannonsState, cannons: ICannon[]) => {
    return cannons.forEach(({ id }) => {
      const { [id]: dummy, ...withoutId } = state.cannonsSet
      // Pretty good right?
      state.cannonsSet = Object.assign({}, withoutId)
      // Prevent icon to remain on map after deletion
      if (state.activeCannonId === id) {
        state.activeCannonId = null
      }
    })
  },
  SET_HIGHLIGHTED_CANNON: (state, payload) => {
    state.highlightedCannon = payload
  },
  SET_HIGHLIGHTED_ALL_CANNONS: (state, payload) => {
    state.highlightedAllCannons = payload
  },
  SET_CANNON_QUICKPANEL_STATE: (state, payload) => {
    state.cannonQuickPanelState = payload
  },
}
