import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { IRadarZoneMaskState } from '@/store/modules/radar_mask_zones/types'

const getDefaultState = (): IRadarZoneMaskState => {
  return {
    editing: false,
    radarMaskZonesSet: {},
    status: '',
    error: ''
  }
}

const state: IRadarZoneMaskState = getDefaultState()

const resetState = (state: IRadarZoneMaskState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
