import { Commit, Dispatch } from 'vuex'
import { apiRequest } from '@/store/utils'
import {
  IDetectionLabelAdd,
  IDetectionLabelUpdate
} from '@/store/modules/detection_labels/types'

const getLabelString = (label): string => {
  if (label == null) return null
  const labelClass = label.name
  const labelSubClass = []
  if (label?.sub_labels && label?.sub_labels.length > 0) {
    label.sub_labels.forEach(subLabel => {
      labelSubClass.push(subLabel.name)
    })
  }

  labelSubClass.unshift(labelClass)
  return labelSubClass.length > 1 ? labelSubClass.join('_') : labelSubClass[0]
}

export default {
  getLabels: async ({ commit }: { commit: Commit }) => {
    return await apiRequest(
      'get',
      `/api/labels`,
      {},
      data => {
        commit('SET_LABELS', data || [])
        return true
      },
      (data, _) => {
        return false
      }
    )
  },
  addLabel: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    label: IDetectionLabelAdd
  ) => {
    return await apiRequest(
      'post',
      `/api/labels`,
      {
        label: label
      },
      data => {
        dispatch('getLabels')
        return {
          data: data,
          error: false
        }
      },
      (data, _) => {
        return {
          data: data.error,
          error: true
        }
      }
    )
  },
  updateLabel: async (
    { commit }: { commit: Commit },
    payload: IDetectionLabelUpdate
  ) => {
    return await apiRequest(
      'patch',
      `api/recordings/${payload.id}`,
      {
        recording: {
          id: payload.id,
          labels: payload.label ? [getLabelString(payload.label)] : []
        }
      },
      data => {
        return {
          data: data,
          error: false
        }
      },
      (data, _) => {
        return {
          data: data.error,
          error: true
        }
      }
    )
  }
}
