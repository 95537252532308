import { IRecording, IRecordingsState, IRecordingsSet } from "./types"

export default {
  status: (state: IRecordingsState) => state.status,
  error: (state: IRecordingsState) => state.error,
  activeRecordingId: (state: IRecordingsState) => state.activeRecordingId,
  recordingsSet: (state: IRecordingsState): IRecordingsSet => state.recordingsSet,
  recordingsList: (state: IRecordingsState): IRecording[] => Object.values(state.recordingsSet),
  recordingsLength: (state: IRecordingsState): number => {
    return state.recordingsSet ? Object.values(state.recordingsSet).length : 0
  },
  activeRecording: (state: IRecordingsState) => state.recordingsSet[state.activeRecordingId],
  nbOfRecordings: (state: IRecordingsState) => Object.keys(state.recordingsSet),
  recordingsById: (state: IRecordingsState) => id => (state.recordingsSet || {})[id],
  recordingById: (state: IRecordingsState) => id => (state.recordingsSet || {})[id],
}
