import { IRecordingsState } from "./types"

export default {
  SET_STATUS: (state: IRecordingsState, status) => (state.status = status),
  SET_ERROR: (state: IRecordingsState, error) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  UPDATE_RECORDING: (state, recordings) => {
    if (recordings && !Array.isArray(recordings)) {
      recordings = [recordings]
    }
    return recordings.forEach(({ id, ...update }) => {
      id && state.recordingsSet && state.recordingsSet[id]
      ? Object.assign(state.recordingsSet[id], update)
      : null
    })
  },
  LOAD_RECORDINGS: (state: IRecordingsState, { recordings }) => {
    if (!recordings) {
      state.recordingsSet = {}
      return
    }

    state.recordingsSet = recordings.reduce((acc, curr) => {
      return Object.assign(acc, { [curr.id]: curr })
    }, {})
  },
  REMOVE_RECORDING: (state: IRecordingsState, id) => {
    if (!id) return
    delete state.recordingsSet[id]
    if (state.activeRecordingId === id) {
      state.activeRecordingId = null
    }
  },
}