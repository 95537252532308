import { ISentriesState } from "./types"

export default {
  SET_STATUS: (state: ISentriesState, status) => (state.status = status),
  SET_ERROR: (state: ISentriesState, error) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  LOAD_SENTRIES: (state: ISentriesState, { sentries }) => {
    if (!sentries) {
      state.sentriesSet = {}
      return
    }

    state.sentriesSet = sentries.reduce((acc, curr) => {
      return Object.assign(acc, { [curr.id]: curr })
    }, {})
  },
  ACTIVATE_SENTRY: (state: ISentriesState, id) => {
    state.activeSentryId = id
  },
  DEACTIVATE_SENTRY: state => state.activeSentryId = null,
  ADD_SENTRY: (state: ISentriesState, { sentry }) => {
    state.sentriesSet = Object.assign(
      {},
      state.sentriesSet,
      { [sentry.id]: sentry}
    )
  },
  UPDATE_SENTRIES: (state: ISentriesState, sentries) => {
    if (sentries && !Array.isArray(sentries)) {
      sentries = [sentries]
    }
    return sentries.forEach(({ id, ...update }) => {
      id && state.sentriesSet && state.sentriesSet[id]
      ? Object.assign(state.sentriesSet[id], update)
      : null
    })
  },
  UPDATE_SENTRY: (state: ISentriesState, sentry) => {
    state.sentriesSet[sentry.id] = {
      ...sentry,
      locatable_id: sentry.locatable_id,
      locatable_type: sentry.locatable_type
    }
  },
  REMOVE_SENTRY: (state: ISentriesState, id) => {
    if (!id) return
    delete state.sentriesSet[id]
    // Prevent icon to remain on map after deletion
    if (state.activeSentryId === id) {
      state.activeSentryId = null
    }
  },
  SET_SCOPED_ERROR_SENTRY: (state, { id, error }) => {
    if (error instanceof Error) {
      error = error.message
    } else if (Array.isArray(error)) {
      error = error[0]
    }
    let sensorTypeScopeError = state.scopedError.sentries
    sensorTypeScopeError = Object.assign({}, sensorTypeScopeError, {
      [id]: error
    })
    state.scopedError = Object.assign({}, state.scopedError, {
      sentries: sensorTypeScopeError
    })
  },
  SOCKET_SENTRIES_CREATE: (state, sentries) => {
    return sentries.forEach(sentry => {
      state.sentriesSet = Object.assign(
        {},
        state.sentriesSet,
        { [sentry.id]: sentry }
      )
    })
  }
}
