import { forward, toPoint } from 'mgrs'
import { point } from '@turf/helpers'
import bearing from '@turf/bearing'
import booleanEqual from '@turf/boolean-equal'
import {
  UnknownBeaconLocation,
  UnknownBeaconSerialNumber
} from '@/store/modules/detection/types'

export const secondsToString = t => {
  t = Math.round(t)
  const d = Math.floor(t / 86400),
    h = Math.floor(t / 3600) % 24,
    m = Math.floor(t / 60) % 60,
    s = t % 60
  return (
    (d > 0 ? d + 'd ' : '') +
    (h > 0 ? h + 'h ' : '') +
    (m > 0 ? m + 'm ' : '') +
    (s > 0 ? s + 's' : '')
  )
}

export const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getGeolocation = () => {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function success(position) {
          resolve([position.coords.latitude, position.coords.longitude])
        },
        function error(error_message) {
          reject(error_message)
        }
      )
    } else {
      reject('Geolocation is not enabled on this browser')
    }
  })
}

export const readTextFile = file => {
  return new Promise(resolve => {
    let reader = new FileReader()
    reader.onload = function() {
      resolve(reader.result)
    }
    reader.readAsText(file)
  })
}

export const groupBy = (arr, property) => {
  return arr.reduce(function(narray, sensor) {
    if (!narray[sensor[property]]) {
      narray[sensor[property]] = []
    }
    narray[sensor[property]].push(sensor)
    return narray
  }, {})
}

export const intToIP = int => {
  const part1 = int & 255
  const part2 = (int >> 8) & 255
  const part3 = (int >> 16) & 255
  const part4 = (int >> 24) & 255
  return `${part4}.${part3}.${part2}.${part1}`
}

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) => {
  if (amount === undefined) return ''
  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount
  const negativeSign = amount < 0 ? '-' : ''
  let i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
  ).toString()
  let j = i.length > 3 ? i.length % 3 : 0
  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : '')
  )
}

export const degToMilsString = deg => {
  return (deg * 17.77778).toFixed(2)
}

export const milsToDegString = mils => {
  return (mils * 0.05625).toFixed(2)
}

export const latLngToMgrs = (latitude, longitude, format = false) => {
  let mgrs = forward([parseFloat(longitude), parseFloat(latitude)])
  if (format) {
    mgrs = mgrs.match(/.{1,5}/g).join(' ')
  }
  return mgrs
}

export const mgrsToLatLng = mgrs => {
  return toPoint(mgrs)
}

export const getRandom = (min, max) => {
  return parseInt(Math.random() * (max - min + 1), 10) + min
}

export const formatFrequency = (frequency, decimals = 0) => {
  const val = Number(frequency)
  if (isNaN(val)) return null
  return parseFloat(val).toFixed(decimals)
}

export const deg2rad = deg => {
  return deg * (Math.PI / 180)
}

export const rad2deg = rad => {
  return rad * (180 / Math.PI)
}

export const computeDirection = (lastPos, currentPos) => {
  const lastPosition = [lastPos.lng, lastPos.lat]
  const { lat, lng } = currentPos
  const point1 = point(lastPosition)
  const point2 = point([lng, lat])
  let direction = bearing(point1, point2).toFixed()
  if (!booleanEqual(point1, point2)) {
    return (360 + parseInt(direction)) % 360
  } else {
    return null
  }
}

export const normaliseCoordinateLength = (value, length = 8) => {
  return value?.toFixed(length) || null
}

export const scaleValue = (value, fromMin, fromMax, toMin, toMax) => {
  const result =
    ((value - fromMin) * (toMax - toMin)) / (fromMax - fromMin) + toMin
  if (result < toMin) {
    return toMin
  } else if (result > toMax) {
    return toMax
  } else {
    return result
  }
}

export const capitalize = value => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const isNullOrUndefined = value => {
  return [null, undefined].includes(value)
}

export const objectsAreEqual = (obj1, obj2) => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false

  for (let key in obj1) {
    if (!(key in obj2) || obj1[key] !== obj2[key]) return false
  }

  for (let key in obj2) {
    if (!(key in obj2) || obj1[key] !== obj2[key]) return false
  }
  return true
}

export const humanReadableTargetID = target_id => target_id.split('-')[0]

export function validBeaconLocation(loc) {
  if (loc && loc !== UnknownBeaconLocation) return loc

  return null
}

export function validBeaconSerialNumber(sn) {
  if (sn && sn !== UnknownBeaconSerialNumber) return sn

  return null
}

export default {
  latLngToMgrs,
  secondsToString,
  timeout,
  getGeolocation,
  readTextFile,
  groupBy,
  intToIP,
  formatMoney,
  milsToDegString,
  degToMilsString,
  getRandom,
  formatFrequency,
  mgrsToLatLng,
  deg2rad,
  rad2deg,
  computeDirection,
  normaliseCoordinateLength,
  scaleValue,
  capitalize,
  isNullOrUndefined,
  objectsAreEqual,
  validBeaconLocation,
  validBeaconSerialNumber
}
