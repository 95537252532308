import { IDetectionState } from '@/store/modules/detection/types'

export default {
  receivedRadarContribution: (state: IDetectionState) => {
    return state.firstContributon.radar
  },
  receivedRfContribution: (state: IDetectionState) => {
    return state.firstContributon.rf
  },
  receivedCameraContribution: (state: IDetectionState) => {
    return state.firstContributon.camera
  },
  getSeenContributors: (state: IDetectionState) => (target_id: string) => {
    return state.seenContributors[target_id] || []
  },
  selectedDetection: (state: IDetectionState) => {
    return state.selectedDetections[0] || null
  },
  selectedDetectionDetails: (state: IDetectionState) => {
    return state.fusionDetections[state.selectedDetections[0]] ?? null
  },
  selectedDetectionHasRfContribution: (
    state: IDetectionState,
    getters
  ): boolean => {
    const detection = getters.selectedDetectionDetails
    if (detection)
      return detection.detection_contributions.some(contributor =>
        ['dsx', 'rfSensor'].includes(contributor.sensor_type)
      )
    else return false
  },
  selectedDetectionHasRadarContribution: (
    state: IDetectionState,
    getters
  ): boolean => {
    const detection = getters.selectedDetectionDetails
    if (detection)
      return detection.detection_contributions.some(
        contributor => contributor.sensor_type === 'radar'
      )
    else return false
  },
  hasDetections: (state: IDetectionState) => {
    return Object.values(state.fusionDetections).length > 0
  },
  fusionDetectionsSelectedFirst: (state: IDetectionState, getters) => {
    const selectedDetectionId = getters.selectedDetection
    const detections = state.fusionDetections
    const detectionIds = Object.keys(detections).filter(
      id => id !== selectedDetectionId
    )
    if (selectedDetectionId) detectionIds.unshift(selectedDetectionId)
    return detectionIds.map(id => detections[id])
  },
  nextDetectionTargetId: (state: IDetectionState, getters, _, rootGetters) => {
    const trackedId = rootGetters['cameras/currentTrackedTargetId']
    const detections = Object.values(state.fusionDetections)
    let trackedIndex = -1
    if (trackedId && detections.length > 0) {
      trackedIndex = detections.findIndex(d => d.target_id === trackedId)
    }
    if (detections[trackedIndex + 1])
      return detections[trackedIndex + 1].target_id
    else if (detections.length > 0) return detections[0].target_id
  }
}
