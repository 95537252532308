import {
  IRfSensorState,
  IRfSensor,
} from '@/store/modules/rf_sensors/types'

export default {
  SET_STATUS: (state: IRfSensorState, status: string) =>
    (state.status = status),
  SET_ERROR: (state: IRfSensorState, error: any) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  LOAD_RF_SENSORS: (state: IRfSensorState, rf_sensors: IRfSensor[]) => {
    let rf = {}
    let dsx = {}

    rf_sensors?.forEach((r: IRfSensor) => {
      if (r.model.includes('dsx_')) {
        dsx[r.id] = r
      } else {
        rf[r.id] = r
      }
    })

    state.rfSensorsSet = rf
    state.dsxSensorsSet = dsx
  },
  ADD_RF_SENSOR: (state: IRfSensorState, rf_sensor: IRfSensor) => {
    const update = Object.assign({}, state.rfSensorsSet)
    update[rf_sensor.id] = rf_sensor
    state.rfSensorsSet = update
  },
  ADD_DSX_SENSOR: (state: IRfSensorState, rf_sensor: IRfSensor) => {
    const update = Object.assign({}, state.dsxSensorsSet)
    update[rf_sensor.id] = rf_sensor
    state.dsxSensorsSet = update
  },
  REMOVE_RF_SENSOR: (state: IRfSensorState, id: number) => {
    if (!id) return
    const update = Object.assign({}, state.rfSensorsSet)
    delete update[id]
    state.rfSensorsSet = update
    // Prevent icon to remain on map after deletion
    if (state.activeRfSensorId === id) {
      state.activeRfSensorId = null
    }
  },
  REMOVE_DSX_SENSOR: (state: IRfSensorState, id: number) => {
    if (!id) return
    const update = Object.assign({}, state.dsxSensorsSet)
    delete update[id]
    state.dsxSensorsSet = update
    // Prevent icon to remain on map after deletion
    if (state.activeRfSensorId === id) {
      state.activeRfSensorId = null
    }
  },
  ACTIVATE_RF_SENSOR: (state: IRfSensorState, id: number) => {
    state.activeRfSensorId = id
  },
  DEACTIVATE_RF_SENSOR: (state: IRfSensorState) => {
    state.activeRfSensorId = null
  },
  SOCKET_RF_SENSORS_CREATE: (state: IRfSensorState, sensors: IRfSensor[]) => {
    return sensors.forEach(sensor => {
      const sensorSetType = sensor?.model?.includes('rf_') ? 'rfSensorsSet' : 'dsxSensorsSet'
      state[sensorSetType] = Object.assign({}, state[sensorSetType], {
        [sensor.id]: sensor
      })
    })
  },
  SOCKET_RF_SENSORS_DELETE: (state: IRfSensorState, sensors: IRfSensor[]) => {
    return sensors.forEach(sensor => {
      const sensorSetType = sensor?.model?.includes('rf_') ? 'rfSensorsSet' : 'dsxSensorsSet'
      const {id} = sensor
      if (!id) return
      const update = Object.assign({}, state[sensorSetType])
      delete update[id]
      state[sensorSetType] = update
      // Prevent icon to remain on map after deletion
      if (state.activeRfSensorId === id) {
        state.activeRfSensorId = null
      }
    })
  }
}
