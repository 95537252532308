import {IUser} from "@/store/modules/users/types";

export default {
  isAdmin: state => state.user.role === 'admin',
  isManager: state => state.user.role === 'manager',
  isAuthorized: (state, getters) => credential => {
    return getters.role && getters.role.cred <= state.cred[credential]
  },
  isDRO: state => state.user.email.slice(-16) === '@droneshield.com',
  role: (state, getters, rootState) => {
    const res = { name: null }
    if (getters.isAdmin) {
      res.name = 'admin'
    } else if (getters.isManager) {
      res.name = 'manager'
    } else {
      const { role } =
        state.sitesAssociations.find(
          s => s.site_id === parseInt(rootState.sites.activeSiteId)
        ) || {}
      res.name = role || 'unknown'
    }
    return { ...res, cred: state.cred[res.name] }
  },
  clientById: state => client_id =>
    state.clientsList.find(c => c.id === client_id),
  recipientsList: state => Object.values(state.recipientsSet),
  permission: state => {
    const { role } = state.user
    return state.role_map[role] || 0
  },
  navItems: (state, getters, rootState) => {
    const r = getters.role || { name: 'unknown', cred: 5 }
    let navItems = state.navItems

    return navItems.filter(n => r.cred <= (state.cred[n.role] || 0))
  },
  theme: state => {
    return state.theme_name
  },
  getDegreeUnit: (state): string => {
    console.log(state.user.settings.milsEnabled ? 'mil' : 'deg')
    return state.user.settings.milsEnabled ? 'mil' : 'deg'
  },
  user: (state): IUser => { return state.user }
}
