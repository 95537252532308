import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { v4 as uuidv4 } from 'uuid'

const getDefaultState = () => {
  let sessionId = sessionStorage.getItem('sessionId') || null
  if (!sessionId) {
    sessionId = uuidv4()
    sessionStorage.setItem('sessionId', sessionId)
  }
  return {
    sessionId: sessionId,
    token: JSON.parse(localStorage.getItem('user-token') || 'null'),
    status: null,
    error: null,
  }
}

const state = getDefaultState()

const resetState = state => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
