import { SensorStatus } from './constants'
import SensorMixin from './Sensor'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('sensors', [
      'cannonsInSentry',
      'gps_compassesInSentry',
      'allSensorsInSentry'
    ]),
    ...mapGetters('radars', ['allRadarsInSentry']),
    ...mapGetters('cameras', ['allCamerasInSentry']),
    ...mapGetters('rf_sensors', [
      'allRfSensorsInSentry',
      'allDsxSensorsInSentry'
    ]),
    ...mapGetters('cannons', ['allCannonsInSentry'])
  },
  methods: {
    $_getStatusColor(sentry) {
      const {
        methods: { $_statusColorByStatus, $_sensorStatus, $_maxStatus }
      } = SensorMixin

      const sensors = this.allSensorsInSentry(sentry) || []
      sensors.cameras = this.allCamerasInSentry(sentry.id)
      sensors.rf_sensors = this.allRfSensorsInSentry(sentry.id)
      sensors.dsx_sensors = this.allDsxSensorsInSentry(sentry.id)
      sensors.cannons = this.allCannonsInSentry(sentry.id)
      sensors.radars = this.allRadarsInSentry(sentry.id)

      // exclude compass from sentry color status
      // delete sensors.gps_compasses
      delete sensors.recordings

      const status = Object.keys(sensors)
        .map(type =>
          sensors[type]
            .map(sensor => $_sensorStatus(sensor, type))
            // First reduction to get all max status per sensorType
            .reduce($_maxStatus, SensorStatus.NIL)
        )
        // Second reduction to get overall max status
        .reduce((arr, cur) => {
          return $_maxStatus(arr, cur)
        }, SensorStatus.NIL)

      return $_statusColorByStatus(status)
    }
  }
}
