import {
  IState,
  ISystemInfo,
} from './types'

export default {
  LOAD_SYS_INFO: (state: IState, data: ISystemInfo) => {
    state.version = data.version
    state.environment = data.environment
  },
  SET_STATUS: (state: IState, status: string) => (state.status = status),
  SET_ERROR: (state: IState, error: Error | Array<string> | string) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  SET_DATA_LOADED_STATE: (state: IState, status: boolean) =>
    (state.dataLoaded = status),
}
