
























































import Vue from 'vue'
import cogs from '@iconify/icons-mdi/cogs'
import IconifyIcon from '@iconify/vue'
import {mapState} from 'vuex'
//@ts-ignore
const SettingsMenu = () => import('./Menu/SettingsMenu')
//@ts-ignore
const UserMenu = () => import('./Menu/UserMenu')

type ObjectValues<T> = T[keyof T]

const STATUS_COLOR = {
  GOOD: 'GOOD',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
} as const

type TStatusColor = ObjectValues<typeof STATUS_COLOR>

interface ITopBarItem {
  badgeShow?: boolean
  badgeColor?: string
  iconFont?: string
  icon: string
  title: string
  name: string
  tooltip: string
}

export default Vue.extend({
  name: 'TopNavActions',
  components: {
    UserMenu,
    SettingsMenu,
    IconifyIcon
  },
  data() {
    return {
      hasWarnings: false,
      systemStatsWarnings: 'good',
      isMenuOpened: {
        user_settings: false,
        settings: false,
        system_warnings: false,
        notes: false
      },
      icons: {
        cogs: cogs
      }
    }
  },
  mounted(): void {
    this.$bus.$on('closeSystemSettingsMenu', () => {
      this.isMenuOpened.settings = false
    })
  },
  computed: {
    ...mapState('site_warnings', ['site_warnings']),
    menuItems(): Record<string, ITopBarItem> {
      return {
        settings: {
          badgeShow: false,
          badgeColor: this.systemStatsWarnings,
          iconFont: 'mdi',
          icon: this.icons.cogs,
          title: 'System Info',
          name: 'settings',
          tooltip: 'System Info'
        },
        user_settings: {
          icon: 'person',
          name: 'user_settings',
          title: 'User Settings',
          tooltip: 'User Settings'
        }
      }
    }
  },
  methods: {
    actionClicked(itemName: string) {
      if (itemName === 'help') {
        this.toggleHelp()
      }
    },
    toggleHelp(): void {
      this.$emitter.emit('help:toggle', {
        action: 'toggle',
        data: null
      })
    },
    iconColor(status: TStatusColor): string {
      switch (status) {
        case STATUS_COLOR.GOOD:
          return 'success'
        case STATUS_COLOR.WARNING:
          return 'primary'
        case STATUS_COLOR.ERROR:
          return 'error'
        default:
          return 'error'
      }
    }
  }
})
