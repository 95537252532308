import { ISentry } from '@/store/modules/sentries/types'

export interface IRadar {
  status_message: string
  direction_offset: number
  id: number
  name: string
  serial_number: string
  created_at: Date
  updated_at: Date
  created_by_user_id: number
  deleted_by_user_id?: any
  updated_by_user_id?: any
  sentry_id: number
  heartbeat_at: Date
  height?: any
  tilt: number
  roll: number
  status?: any
  radar_lat?: any
  radar_lon?: any
  radar_alt?: any
  track_tail_length: number
  radar_type: EModel
  radar_tilt?: any
  imu_tilt?: any
  radar_az_min_search: number
  radar_az_max_search: number
  radar_az_min_track?: any
  radar_az_max_track?: any
  radar_az_step?: any
  radar_el_min_search?: any
  radar_el_max_search?: any
  radar_el_min_track?: any
  radar_el_max_track?: any
  radar_el_step?: any
  meta?: any
  client_time?: any
  status_requested?: any
  status_requested_at?: any
  ip?: any
  smarthub_id?: any
  software_version?: any
  channel: number
  netmask?: any
  imu_roll: number
  tcm_num: number
  seconds_since_last_update: number
  status_color: string
  latitude: number
  longitude: number
  altitude: number
  direction: number
  timeoutError: number
  range: number
  mask_clutter_width: number
  min_rcs: number
  max_rcs: number
  min_max_rcs?: number[]
}

export enum EModel {
  RadarZeroString = 'radar_zero',
  RadaString = 'rada'
}

export interface IRadarState {
  radarsSet: IRadarsSet
  activeRadarId: number
  error: string
  status: string
  errorByRadar: IRadarsError
}

export interface IRadarsSet {
  [key: number]: IRadar
}

export interface IRadarsError {
  [key: number]: string
}

export interface IRadarResponse {
  radar: IRadar
}

export interface IRadarErrorResponse {
  error: string
}

export interface IRadarListResponse {
  radars: IRadar[]
}

//for select input
export const RadarTypes = [
  { value: 'radar_zero', text: 'Radar Zero' },
  { value: 'rada', text: 'RADA' }
]

export const buildSensorObject = (
  site: number,
  sentries: ISentry[],
  sensorsSet: {}
): Record<string, IRadar> => {
  const siteSentryIds = sentries.map((sentry: ISentry) => sentry.id)
  const radarObject = {}
  Object.values(sensorsSet).forEach((radar: IRadar) => {
    if (siteSentryIds.includes(radar.sentry_id)) {
      radarObject[`${radar.id}-${radar.sentry_id}`] = radar
    }
  })
  return radarObject
}
