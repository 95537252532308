import Sensor from '../../utils/Module/Sensor'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const endpoints = [
  {
    endpoint: 'radars',
    formKey: 'radar'
  },
  {
    endpoint: 'gps_compasses',
    formKey: 'gps_compass'
  },
  {
    endpoint: 'recordings',
    formKey: 'recording'
  },
  {
    endpoint: 'discovair_sensors',
    formKey: 'discovair_sensor'
  }
]

const identityTypeMapping = ({ type, serial }) => {
  return {
    radarone: {
      group: 'radars',
      type: 'radar',
      name: 'RadarOne'
    },
    radarzero: {
      group: 'radars',
      type: 'radar',
      name: 'RadarZero'
    },
    rfone: {
      group: 'rf_sensors',
      type: 'rf',
      name: 'RfOne',
      model: 'rf_one'
    },
    rftwo: {
      ...rfSerialTypeMapping(serial)
    },
    cannon3b: {
      group: 'cannons',
      type: 'cannon',
      name: 'Cannon 3B'
    },
    cannon5b: {
      group: 'cannons',
      type: 'cannon',
      name: 'Cannon 5B'
    },
    'cannon5b.1': {
      group: 'cannons',
      type: 'cannon',
      name: 'Cannon 5B'
    },
    cannonmk2: {
      group: 'cannons',
      type: 'cannon',
      name: 'Cannon 5B Mk2'
    },
    discovairsensor: {
      group: 'discovair_sensors',
      type: 'discovair',
      name: 'Discovair Sensor'
    },
    compass: {
      group: 'gps_compasses',
      type: 'compass',
      name: 'GPS Compass'
    },
    camera: {
      group: 'cameras',
      type: 'camera',
      name: 'DroneOpt'
    },
    rfpatrol: {
      group: 'rf_sensors',
      type: 'rf',
      name: 'RfPatrol',
      model: 'rf_patrol'
    }
  }[type]
}

const rfSerialTypeMapping = serial => {
  var rfPrefix = serial?.slice(0, 10) || '0268578542'

  const sensorOptions = {
    '0268578542': {
      group: 'rf_sensors',
      type: 'rf',
      name: 'RfOne Mk2',
      model: 'rf_two'
    },
    '0336088542': {
      group: 'rf_sensors',
      type: 'rf',
      name: 'RfZero',
      model: 'rf_zero'
    },
    '0393909542': {
      group: 'dsx_sensors',
      type: 'dronesentryx',
      name: 'DroneSentry-X',
      model: 'dsx_direct'
    },
    '0571309542': {
      group: 'dsx_sensors',
      type: 'dronesentryx',
      name: 'DroneSentry-X',
      model: 'dsx_direct_no_jam'
    },
    '0350198542': {
      group: 'rf_sensors',
      type: 'rf',
      name: 'RfPatrol',
      model: 'rf_patrol'
    }
  }

  // If rfPrefix doesn't match, we default to rftwo
  if (sensorOptions[rfPrefix] === undefined) {
    rfPrefix = '0268578542'
  }

  return sensorOptions[rfPrefix]
}

const getDefaultState = () => {
  return {
    updates: {},
    events: [],
    origins: [],
    detections: {},
    radarsDetections: {},
    tails: {},
    rfDetections: {},
    rfIntersections: {},
    activeKey: null,
    activeCokey: null,
    activeBeam: null,
    lastMeta: null,
    endpoints: endpoints.map(e => e.endpoint),
    rfPerfGrid: null,
    radarZMaskTotal: 0,
    radarAGLMasks: {},
    radarRMasks: [],
    cannonQuickPanelState: false,
    identities: {},
    smarthubs: {},
    identityTypeMapping,
    scopedError: {} //sensor errors triggered out of the sensor form, i.e. enable/disable/jam
  }
}

const modules = endpoints
  .map(({ endpoint, formKey }) => {
    return new Sensor({ endpoint, formKey }).module
  })
  .reduce((acc, curr) => {
    return {
      state: { ...acc.state, ...curr.state },
      getters: { ...acc.getters, ...curr.getters },
      actions: { ...acc.actions, ...curr.actions },
      mutations: { ...acc.mutations, ...curr.mutations }
    }
  }, {})

const state = getDefaultState()

const resetState = state => {
  Object.assign(state, getDefaultState())
}

const wrapperGetters = {
  originsList: state => state.origins,
  getOrigin: state => ckey =>
    state.origins.find(o => o.correlation_key === ckey),
  allRadarsInSite: state => Object.values(state.radarsSet)
}

export default {
  namespaced: true,
  state: { ...state, ...modules.state },
  getters: { ...modules.getters, ...getters, ...wrapperGetters },
  actions: { ...actions, ...modules.actions },
  mutations: { ...mutations, ...modules.mutations, resetState }
}
