import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { ICannon, ICannonsState } from './types'

const getDefaultState = (): ICannonsState => {
  return {
    cannonsSet: [],
    activeCannonId: null,
    status: '',
    error: '',
    highlightedCannon: null, //this the id of highlighted cannon/dsx jammer
    highlightedAllCannons: false,
  }
}

const state = getDefaultState()

const resetState = (state: ICannon) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
