import { Commit, Dispatch, Getter } from 'vuex'
import { apiRequest } from '@/store/utils'
import {
  IRadar, IRadarErrorResponse,
  IRadarListResponse,
  IRadarResponse,
  IRadarState
} from '@/store/modules/radars/types'

export default {
  CLEAR_STATUS: ({ commit }: { commit: Commit }) => {
    commit('SET_STATUS', null)
    commit('SET_ERROR', null)
  },
  FETCH_RADARS: async ({ commit }: { commit: Commit }, siteId: number) => {
    await apiRequest(
      'get',
      `/api/sites/${siteId}/radars`,
      {},
      (data: IRadarListResponse) => {
        commit('LOAD_RADARS', data.radars)
        commit('SET_STATUS', 'OK')
      },
      (data: IRadarErrorResponse, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  CREATE_RADAR: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: { siteId: number; radar: IRadar }
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'post',
      `/api/sites/${args.siteId}/radars`,
      { radar: args.radar },
      (data: IRadarResponse, _: any) => {
        commit('SET_STATUS', 'OK')
        commit('ADD_RADAR', data.radar)
        dispatch('SELECT_RADAR', data.radar.id)
      },
      (data: IRadarErrorResponse, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  UPDATE_RADAR: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: { siteId: number; radar: IRadar }
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'put',
      `/api/sites/${args.siteId}/radars/${args.radar.id}`,
      { radar: args.radar },
      (data: IRadarResponse, _: any) => {
        commit('SET_STATUS', 'OK')
        commit('ADD_RADAR', data.radar)
        dispatch('SELECT_RADAR', data.radar.id)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  DELETE_RADAR: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: { siteId: number; radar: IRadar }
  ) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'delete',
      `/api/sites/${args.siteId}/radars/${args.radar.id}`,
      {},
      (data, _) => {
        commit('REMOVE_RADAR', args.radar.id)
        commit('SET_STATUS', 'OK')
        dispatch('UNSELECT_RADAR')
      },
      (data: IRadarErrorResponse, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  SELECT_RADAR: ({ commit }: { commit: Commit }, id: number) => {
    commit('ACTIVATE_RADAR', id)
  },
  UNSELECT_RADAR: (
    { commit, state }: { commit: Commit; state: IRadarState },
    id: number
  ) => {
    if (!state.activeRadarId) return
    commit('DEACTIVATE_RADAR', id)
  },
  SOCKET_RADARS_UPDATE: ({ commit }: { commit: Commit }, sensors: IRadar[]) => {
    sensors.forEach(sensor => commit('ADD_RADAR', sensor))
  },
  TOGGLE_RADAR: async ({ commit }: { commit: Commit }, args: { radar: IRadar, siteId: number }) => {
    commit('SET_STATUS', 'LOADING')
    const newStatus = args.radar.status == 'transmitting' ? 'idle' : 'transmitting'
    return await apiRequest(
      'patch',
      `/api/sites/${args.siteId}/radars/${args.radar.id}`,
      {
        radar: {
          status: newStatus,
        }
      },
      _ => {
        commit('SET_STATUS', 'OK')
      },
      (data: IRadarErrorResponse, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit(`SET_SCOPED_ERROR_RADAR`, {
          id: args.radar.id,
          error: new Error(data.error)
        })
      }
    )
  },
}
