import actions from './actions'

import { IRadarState } from './types'
import getters from '@/store/modules/radars/getters'
import mutations from '@/store/modules/radars/mutations'

const getDefaultState = (): IRadarState => {
  return {
    radarsSet: {},
    errorByRadar: {},
    activeRadarId: null,
    status: '',
    error: ''
  }
}

const state = getDefaultState()

const resetState = (state: IRadarState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
