export default {
  allSensorsInSentry: (state, getters) => sentry => {
    return state.endpoints.reduce((acc, cur) => {
      return Object.assign(acc, { [cur]: getters[`${cur}InSentry`](sentry) })
    }, {})
  },
  allRadars: state => {
    return Object.values(state.radarsSet) || []
  },
  hasRadar: state => Object.keys(state.radarsSet).length > 0,
  allRadarMasks: state => {
    return state['radarZMasks'] || {}
  },
  aglMasks: state => {
    return state.radarAGLMasks || {}
  },

  activeSensorId: state => sensorType => {
    return state[`active${sensorType}Id`]
  },
  meta: state => state.lastMeta,
  rfPerfGrid: state => state.rfPerfGrid,
  radarsDetectionsArray: state => Object.values(state.radarsDetections),
  rfDetectionsArray: state => Object.values(state.rfDetections),
  rfIntersectionsArray: state => Object.values(state.rfIntersections),
  detectionByTargetId: state => id => (state.radarsDetections || {})[id],
  rfDetectionById: state => id => (state.rfDetections || {})[id],
  rfIntersectionsById: state => id => (state.rfIntersections || {})[id],
  cannonQuickPanelState: state => state.cannonQuickPanelState,
  nameForIdentityType: state => identity => {
    return state.identityTypeMapping(identity).name
  },
  serialsInActiveSite: state => {
    return state.endpoints.reduce((acc, endpoint) => {
      acc.push(
        ...Object.values(state[`${endpoint}Set`]).map(d => d.serial_number)
      )
      return acc
    }, [])
  },
  totalZMaskZones: state => {
    return state['radarZMaskTotal'] || []
  },
  smarthubs: state => state.smarthubs,
  smarthubIP: state => {
    const smarthubs = Object.values(state.smarthubs)

    if (!smarthubs.length) return ''

    const ip = smarthubs[0].ip
    if (!ip || ip === 'http://' || ip.includes('undefined')) return ''

    return ip
  }
}
