import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { ISentriesState } from './types';

const getDefaultState = (): ISentriesState => {
  return {
    sentriesSet: {},
    activeSentryId: null,
    status: '',
    error: '',
  }
}

const state = getDefaultState()

const resetState = (state: ISentriesState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}

