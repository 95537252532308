import { Commit } from 'vuex'
import { apiRequest } from '@/store/utils'
import {
  IRadarMaskErrorResponse,
  IRadarMaskListResponse, IRadarMaskZoneResponse,
  IRadarZoneMask,
  IRadarZoneMaskModel
} from '@/store/modules/radar_mask_zones/types'
import { IRadar, IRadarErrorResponse } from '@/store/modules/radars/types'
import pick from 'lodash/pick'

const sanitizeZMaskParams = (mask: any) => {
  return pick(mask, [
    'radar_id',
    'azimuth_min',
    'azimuth_max',
    'elevation_min',
    'elevation_max',
    'range_min',
    'range_max',
    'rcs_min',
    'rcs_max',
    'hard_mask',
    'active',
    'name'
  ])
}

export default {
  setMaskEditingState: async ({ commit }, editing: boolean) => {
    commit('SET_MASK_EDIT_STATE', editing)
  },
  FETCH_RADAR_MASK_ZONES_PER_SITE: async ({ commit }: { commit: Commit }, siteId: number) => {
    await apiRequest(
      'get',
      `/api/sites/${siteId}/radar_mask_zones`,
      {},
      (data: IRadarMaskListResponse) => {
        commit('SET_ZONE_MASKS', data.radar_mask_zones)
        commit('SET_STATUS', 'OK')
      },
      (data: IRadarMaskErrorResponse, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  UPDATE_RADAR_MASK_ZONE: async (
    { commit }: { commit: Commit },
    args: { siteID: number, mask: IRadarZoneMaskModel }
  ) => {
    commit('SET_STATUS', 'LOADING')

    return await apiRequest(
      'patch',
      `/api/sites/${args.siteID}/radars/${args.mask.radar_id}/radar_mask_zones/${args.mask.id}`,
      { radar_mask_zone: sanitizeZMaskParams(args.mask) },
      (data: IRadarMaskZoneResponse) => {
        commit('SET_STATUS', 'OK')
        commit('ADD_OR_UPDATE_RADAR_MASK_ZONE', data.radar_mask_zone)
      },
      (data: IRadarErrorResponse, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  CREATE_RADAR_MASK_ZONE: async (
    { commit }: { commit: Commit },
    args: { siteID: number, mask: IRadarZoneMaskModel }
  ) => {
    commit('SET_STATUS', 'LOADING')

    return await apiRequest(
      'post',
      `/api/sites/${args.siteID}/radars/${args.mask.radar_id}/radar_mask_zones`,
      { radar_mask_zone: sanitizeZMaskParams(args.mask) },
      (data: IRadarMaskZoneResponse) => {
        commit('SET_STATUS', 'OK')
        commit('ADD_OR_UPDATE_RADAR_MASK_ZONE', data.radar_mask_zone)
      },
      (data: IRadarErrorResponse, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  DELETE_RADAR_MASK_ZONE: async (
    { commit }: { commit: Commit },
    args: { siteID: number, mask: IRadarZoneMaskModel }
  ) => {
    commit('SET_STATUS', 'LOADING')

    return await apiRequest(
      'delete',
      `/api/sites/${args.siteID}/radars/${args.mask.radar_id}/radar_mask_zones/${args.mask.id}`,
      {},
      _ => {
        commit('SET_STATUS', 'OK')
        commit('DELETE_RADAR_MASK_ZONE', args.mask)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  }
}
