import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import jwt_decode from 'jwt-decode'
import moment from 'moment-timezone'

Vue.use(Router)

const checkTokenExpiration = () => {
  const userData = JSON.parse(localStorage.getItem('user-token')) || null
  const token = userData?.token
  if (token) {
    // check if token is expired
    const decoded = jwt_decode(token)
    const tokenExp = moment.unix(decoded.exp)
    if (moment().isAfter(tokenExp)) {
      store.dispatch('auth/AUTH_LOGOUT')
    }
  }
}

const ifAuthenticated = (to, from, next) => {
  checkTokenExpiration()
  store.commit('plans/STOP_PLANNING_TOOL', to)
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (isAuthenticated) {
    const hasDataLoaded = store.getters['system/hasDataLoaded']
    if (hasDataLoaded) {
      return next()
    } else {
      return next('/dataloader')
    }
  } else {
    return next('/login')
  }
}

const LoginView = () =>
  import(/* webpackChunkName: "group-login" */ '../views/Login')

const PlansView = () =>
  import(/* webpackChunkName: "group-plan" */ '../views/Plan')

const UsersView = () =>
  import(/* webpackChunkName: "group-users" */ '../views/Users')

const ResetPasswordView = () =>
  import(/* webpackChunkName: "group-res-passd" */ '../views/ResetPassword')

const DataLoader = () =>
  import(
    /* webpackChunkName: "group-data-loader" */ /* webpackPrefetch: true */ '../views/DataLoaderView.vue'
    )

const EditSensor = () =>
  import(
    /* webpackChunkName: "group-sensors" */ '../components/Sensors/editSensor.vue'
    )

const Sensors = () =>
  import(/* webpackChunkName: "group-sensors" */ '../views/Sensors.vue')

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: PlansView,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView,
      beforeEnter: (to, from, next) => {
        const isAuthenticated = store.getters['auth/isAuthenticated']
        return isAuthenticated ? next('/') : next()
      }
    },
    {
      path: '/plans',
      name: 'Plans',
      component: PlansView,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/users-management',
      component: UsersView,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reset_password/:token',
      name: 'ResetPassword',
      props: true,
      component: ResetPasswordView
    },
    {
      path: '/dataloader',
      name: 'DataLoader',
      props: true,
      component: DataLoader
    },
    {
      path: '/sensors',
      name: 'sensors',
      component: Sensors,
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: ':sensorType/new',
          component: EditSensor,
          name: 'sensors.new',
          props: true
        },
        {
          path: ':sensorType/:id',
          component: EditSensor,
          name: 'sensors.edit',
          props: true
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  mode: 'history'
})

export default router
