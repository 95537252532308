import { IRadarZoneMask, IRadarZoneMaskModel, IRadarZoneMaskState } from '@/store/modules/radar_mask_zones/types'
import { IRadarState } from '@/store/modules/radars/types'

export default {
  SET_MASK_EDIT_STATE: (state: IRadarZoneMaskState, editing: boolean) => {
    state.editing = editing
  },
  SET_ZONE_MASKS: (state: IRadarZoneMaskState, masks: IRadarZoneMask[]) => {
    const radarMaskZonesSet = {}

    masks.map((mask: IRadarZoneMask) => {
      let maskList = radarMaskZonesSet[mask.radar_id] || []
      maskList.push(mask)

      radarMaskZonesSet[mask.radar_id] = maskList
    })

    state.radarMaskZonesSet = radarMaskZonesSet
  },
  SET_STATUS: (state: IRadarZoneMaskState, status: string) => (state.status = status),
  SET_ERROR: (state: IRadarZoneMaskState, error: any) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  ADD_OR_UPDATE_RADAR_MASK_ZONE: (state: IRadarZoneMaskState, mask: IRadarZoneMaskModel) => {
    const sensorID = mask.radar_id

    const maskObj = state['radarMaskZonesSet']

    var rec = maskObj[sensorID]?.find(m => m.id === mask.id)

    if (!state['radarMaskZonesSet'][sensorID]) {
      state['radarMaskZonesSet'][sensorID] = []
    }

    return rec
      ? (state['radarMaskZonesSet'][sensorID] = maskObj[sensorID].map(m =>
        m.id === mask.id ? mask : m
      ))
      : state['radarMaskZonesSet'][sensorID].push(mask)
  },
  DELETE_RADAR_MASK_ZONE: (state: IRadarZoneMaskState, mask: IRadarZoneMaskModel) => {
    state.radarMaskZonesSet[mask.radar_id] = state.radarMaskZonesSet[mask.radar_id].filter(
      m => m.id !== mask.id
    )
  },
}
