import { apiRequest } from '../../utils'

export default {
  AUTH_REQUEST: async (
    { commit, dispatch, rootState },
    { username, password }
  ) => {
    localStorage.clear()
    commit('AUTH_REQUEST')
    let loginErrorMsg = 'You have entered an invalid username or password'
    return await apiRequest(
      'post',
      `/login.json`,
      { user: { username, password } },
      async (data: { user: any; token_expires_at: any }, headers: { authorization: string }) => {
        const { user, token_expires_at } = data

        if (headers.authorization) {
          user.token = headers.authorization.split(' ')[1]
          user.expires_at = token_expires_at
        }

        localStorage.setItem('user-token', JSON.stringify(user))

        commit('AUTH_SUCCESS', user)
      },
      (data, _) => {
        if (data.error) {
          loginErrorMsg = data.error
        }
        commit('AUTH_ERROR', loginErrorMsg)
      }
    )
  },
  PASSWORD_RESET_REQUEST: async (
    { commit, dispatch, rootState },
    { email }
  ) => {
    commit('AUTH_REQUEST')
    await apiRequest(
      'post',
      `/request_reset_password.json`,
      {
        user: { email }
      },
      () => {
        commit('CLEAR_ERROR')
      },
      err => {
        commit('AUTH_ERROR', err?.error || err.toString())
      }
    )
  },
  AUTH_LOGOUT: async ({ dispatch }) => {
    dispatch('_CLEAR_DATA', null, { root: true })
  },
  CLEAR_ERROR({ commit }) {
    commit('CLEAR_ERROR')
  }
}
