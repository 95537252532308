import { ISentry } from '@/store/modules/sentries/types'
import {
  buildSensorObject,
  IRadar, IRadarsError,
  IRadarState
} from '@/store/modules/radars/types'
import { ICameraState } from '@/store/modules/cameras/types'

export default {
  status: (state: IRadarState): string => state.status,
  error: (state: IRadarState): string => state.error,
  activeRadarId: (state: IRadarState): number => state.activeRadarId,
  radarsSet: (state: IRadarState): Record<number, IRadar> => state.radarsSet,
  radarById: (state: IRadarState) => (id: number): IRadar => {
    return (state.radarsSet || {})[id]
  },
  radarsInSite: (state: IRadarState) => (
    site: number,
    sentries: ISentry[]
  ): Record<string, IRadar> => {
    return buildSensorObject(site, sentries, state.radarsSet)
  },
  allRadarsInSentry: (state: IRadarState) => (sentryId: number): IRadar[] => {
    return Object.values(state.radarsSet).filter(
      (radar: IRadar) => radar.sentry_id === sentryId
    )
  },
  hasRadar: (state: IRadarState): boolean => {
    return state.radarsSet && Object.keys(state.radarsSet).length > 0
  },
  radarsLength: (state: IRadarState): number => {
    return (state.radarsSet && Object.keys(state.radarsSet).length) || 0
  },
  errorsByRadar: (state: IRadarState): IRadarsError => {
    return state.errorByRadar
  }
}
