import { apiRequest, axiosInstance, constructUrl } from '@/store/utils'
import moment from 'moment-timezone'

export default {
  setShowRadarRfWhitelisted: ({ commit }, value) => {
    commit('SET_SHOW_RADAR_RF_WHITELISTED', value)
  },
  setShowRfFiltered: ({ commit }, value) => {
    commit('SET_SHOW_RF_FILTERED', value)
  },
  setShowRfWhitelisted: ({ commit }, value) => {
    commit('SET_SHOW_RF_WHITELISTED', value)
  },
  downloadAnalyticsData: async ({}, payload) => {
    const url = constructUrl('/', {
      queryParams: payload.params,
      path: `/reports.${payload.format}`
    })

    const start_time = moment
      .utc(payload.params.start_time)
      .tz(payload.timezone)
      .format('YYYY-MM-DDTHH:mm')
    const end_time = moment
      .utc(payload.params.end_time)
      .tz(payload.timezone)
      .format('YYYY-MM-DDTHH:mm')

    return await apiRequest(
      'get',
      url,
      {
        invalidateCache: payload.invalidateCache
      },
      data => {
        const blob = new Blob([data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${payload.params.type}_${start_time}_to_${end_time}.csv`
        return link
      },
      err => {
        return err
      }
    )
  },
  fetchAnalyticsData: async ({}, payload) => {
    const url = constructUrl('', {
      queryParams: payload.params,
      path: `/reports.${payload.format}`
    })

    return await apiRequest(
      'get',
      url,
      {
        invalidateCache: payload.invalidateCache
      },
      data => {
        return data
      },
      err => {
        return err
      }
    )
  },
  FETCH_REPORTS: async (
    { commit },
    {
      format = 'csv',
      type,
      start_time,
      end_time,
      site_id,
      whitelisted = false,
      filtered = false
    }
  ) => {
    commit('SET_STATUS', 'LOADING')
    try {
      const { data } = await axiosInstance.get(
        `/reports.${format}?type=${type}&start_time=${start_time}&end_time=${end_time}&site_id=${site_id}&whitelisted=${whitelisted}&filtered=${filtered}`
      )
      if (format === 'csv') {
        const blob = new Blob([data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${type}_${start_time}_to_${end_time}.csv`
        commit('SET_STATUS', 'READY')
        return link
      } else {
        const { events = [] } = data || {}

        const tracks = data.tracks
          ? data.tracks.reduce((acc, cur) => {
              return Object.assign(acc, { [cur[0].event_summary_id]: cur })
            }, {})
          : {}

        data.events = events.reduce((acc, cur) => {
          return Object.assign(acc, {
            [cur.id]: { ...cur, tracks: tracks[cur.id] }
          })
        }, {})

        delete data.tracks

        // commit("SET_REPORT", data);
        commit('SET_STATUS', 'READY')
        return data
      }
    } catch (err) {
      commit('SET_STATUS', 'ERROR')
      commit('SET_ERROR', err.message)
    }
  },
  FETCH_EVENT: async ({ commit }, id) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/event_summaries/${id}`
      )
      // commit("UPDATE_REPORT", { id: data.event.id, tracks: data.tracks });
      return { ...data, id }

      // return commit("SET_STATUS", "READY");
    } catch (err) {
      commit('SET_STATUS', 'ERROR')
      commit('SET_ERROR', err.message)
    }
  },
  FETCH_UPTIME: async ({ commit }, { id, start_time, end_time }) => {
    commit('SET_STATUS', 'LOADING')
    try {
      const { data } = await axiosInstance.get(
        `/sites/${id}/rf_uptimes.json?start_time=${start_time}&end_time=${end_time}&show_intervals=false`
      )
      commit('SET_STATUS', 'READY')
      return { ...data, id }
    } catch (err) {
      commit('SET_STATUS', 'ERROR')
      commit('SET_ERROR', err.message)
    }
  },
  FLAG_EVENT: async ({ commit }, id) => {
    try {
      const { statusText } = await axiosInstance({
        method: 'patch',
        url: `/api/event_summaries/${id}`,
        data: { event: { false_positive: true } }
      })
      commit('SET_STATUS', statusText)
    } catch (err) {
      commit('SET_STATUS', 'ERROR')
      commit('SET_ERROR', err.message)
    }
  },
  setShowFalsePositives: async ({ commit }, status) => {
    commit('SET_SHOW_FALSE_POSITIVES', status)
  },
  updateEventSummary: async ({}, payload) => {
    try {
      return await apiRequest(
        'patch',
        `/api/event_summaries/${payload.id}`,
        payload.data,
        data => {
          return data
        },
        () => {
          return null
        }
      )
    } catch (e) {
      return null
    }
  },
  downloadEventData: async ({}, payload) => {
    return await apiRequest(
      'get',
      `/api/sites/${payload.site_id}/event_summaries/${payload.id}/export`,
      {
        invalidateCache: payload.invalidateCache
      },
      data => {
        const blob = new Blob([data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `event-${payload.id}-${new Date().toISOString()}.csv`
        return link
      },
      err => {
        return err
      }
    )
  }
}
