import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { getPersistent } from '@/store/utils'
import { IMapState, Map } from './types'

const toQuadKey = (x: number, y: number, z: number) => {
  var index = ''
  for (var i = z; i > 0; i--) {
    var b = 0
    var mask = 1 << (i - 1)
    if ((x & mask) !== 0) b++
    if ((y & mask) !== 0) b += 2
    index += b.toString()
  }
  return index
}

const ALL_MAPS = {
    basic: {
      name: "MapTiler Basic",
      url: "https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=",
      index: 0
    },
    bright: {
      name: "MapTiler Bright",
      url: "https://api.maptiler.com/maps/bright-v2/{z}/{x}/{y}.png?key=",
      index: 1
    },
    openStreetMap: {
      name: "MapTiler OpenStreetMap",
      url: "https://api.maptiler.com/maps/openstreetmap/{z}/{x}/{y}.jpg?key=",
      index: 2
    },
    satellite: {
      name: "MapTiler Satellite",
      url: "https://api.maptiler.com/maps/satellite/{z}/{x}/{y}.jpg?key=",
      index: 3
    },
    streets: {
      name: "MapTiler Streets",
      url: "https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=",
      index: 4
    },
    toner: {
      name: "MapTiler Toner",
      url: "https://api.maptiler.com/maps/toner-v2/{z}/{x}/{y}.png?key=",
      index: 5
    },
    topo: {
      name: "MapTiler Topology",
      url: "https://api.maptiler.com/maps/topo-v2/{z}/{x}/{y}.png?key=",
      index: 6
    }
  }

const getDefaultState = (): IMapState => {
  return {
    mapZoom: 12,
    uploadFileId: null,
    uploadingMap: false,
    showAddMapLayerDialog: false,
    mapCancelToken: null,
    mapChunks: [],
    selectedMapLayers: [],
    allMapLayers: ALL_MAPS,
    siteMapLayerMapping: Object.keys(ALL_MAPS),
    activeMapLayerName: ALL_MAPS.basic.name,
    activeMapLayer: ALL_MAPS.basic,
    activeMapLayerKey: 'basic',
    compassGraphicDirection: 0,
    activeMapId: null,
    exporting: false,
    status: null,
    searchResult: null,
    activeMapLayers: getPersistent('activeMapLayers', [
      'ESRI World Imagery Satellite',
      'ESRI World Imagery Topology'
    ]),
    mapLayerID: getPersistent('mapLayerID', 1),
    mapTilerOnlineKey: "",
    error: null
  }
}

const state = getDefaultState()

const resetState = (state: IMapState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
