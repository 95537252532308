import { ISentry, ISentriesState, ISentriesSet } from "./types"

// Taken directly from getters in utils/Module
export default {
  status: (state: ISentriesState) => state.status,
  error: (state: ISentriesState) => state.error,
  activeSentryId: (state: ISentriesState) => state.activeSentryId,
  sentriesSet: (state: ISentriesState): ISentriesSet => state.sentriesSet,
  sentriesList: (state: ISentriesState): ISentry[] => Object.values(state.sentriesSet),
  sentriesLength: (state: ISentriesState): number => {
    return state.sentriesSet ? Object.values(state.sentriesSet).length : 0
  },
  activeSentry: (state: ISentriesState) => state.sentriesSet[state.activeSentryId],
  nbOfSentries: (state: ISentriesState) => Object.keys(state.sentriesSet),
  sentriesById: (state: ISentriesState) => id => (state.sentriesSet || {})[id],
  sentryById: (state: ISentriesState) => id => (state.sentriesSet || {})[id],
  sentriesInSite: (state: ISentriesState) => id => {
    const sentries = Object.values(state.sentriesSet)
    return sentries.filter((sentry: ISentry) => sentry.site_id == id)
  }
}
