import { Commit, Dispatch, Getter } from 'vuex'
import omit from 'lodash/omit'
import { apiRequest, axiosInstance } from '../../utils'

import { ILocatorsResponse } from './types'

export default {
  FETCH_SENTRIES: async ({ commit, rootState }) => {
    const { activeSiteId } = rootState.sites || {}
    await apiRequest(
      'get',
      `api/sites/${activeSiteId}/sentries`,
      {},
      (data, headers) => {
        commit(`LOAD_SENTRIES`, data)
      },
      (data, _) => {
        commit(`LOAD_SENTRIES`, [])
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_SENTRY_LOCATORS: async ({ commit }: { commit: Commit }, { sentry_id, site_id}) => {
    try {
      const { data }: {data: ILocatorsResponse} = await axiosInstance.get(
        `/api/sites/${site_id}/sentries/${sentry_id}/locators`
      )
      return data.locators
    } catch (err) {
      commit('SET_STATUS', 'FAILED')
      commit('SET_ERROR', err.message)
    }
  },
  SELECT_SENTRY: ({ commit, getters, dispatch }, id) => {
    commit('ACTIVATE_SENTRY', id)
    if (id !== null) {
      dispatch(
        'sensors/selectUniqueGroupForSentry',
        getters.sentriesSet[id],
        { root: true }
      )
    }
  },
  UNSELECT_SENTRY: ({ commit, getters }) => {
    if (getters.activeSentryId) {
      commit('DEACTIVATE_SENTRY')
    }
  },
  CREATE_SENTRY: async({ commit, dispatch }, sentry) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'post',
      `/api/sites/${sentry.site_id}/sentries`,
      { sentry },
      (data, _) => {
        commit('ADD_SENTRY', data)
        commit('SET_STATUS', 'OK')
        if (data && data.id) {
          dispatch('SELECT_SENTRY', data.id)
        }
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  UPDATE_SENTRY: async ({ commit }, sentry) => {
    let { scopedError = false } = sentry
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'patch',
      `/api/sites/${sentry.site_id}/sentries/${sentry.id}`,
      { sentry: omit(sentry, ['id', 'scopedError']) },
      (data, _) => {
        commit(`UPDATE_SENTRY`, data.sentry)
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        if (scopedError) {
          commit('SET_SCOPED_ERROR_SENTRY', {
            id: sentry.id,
            error: new Error(data.error)
          })
        } else {
          commit(`SET_ERROR`, new Error(data.error))
        }
      }
    )
  },
  DELETE_SENTRY: async ({ commit, dispatch }, sentry) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'delete',
      `/api/sites/${sentry.site_id}/sentries/${sentry.id}`,
      { sentry },
      (data, _) => {
        commit('REMOVE_SENTRY', sentry.id)
        commit('SET_STATUS', 'OK')
        dispatch('UNSELECT_SENTRY')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  SOCKET_SENTRIES_UPDATE: ( { commit }, updates) => {
    return updates.forEach(({ id, ...update }) => {
      commit('UPDATE_SENTRIES', [{ id, ...update }])
    })
  }
}
